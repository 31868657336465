import React from "react";
import { any } from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = {
  root: {
    width: "100vw",
    overflowX: "hidden",
  },
};

const page = withStyles(styles)(({ classes, children }) => (
  <div className={classes.root}>{children}</div>
));

page.propTypes = {
  children: any.isRequired,
};

export default page;
