import React from "react";
import { any, bool } from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import classnames from "classnames";

const getMaxWidth = (props) =>
  props.fullWidth ? undefined : props.narrow ? 800 : 1200;

const styles = (theme) => ({
  root: {
    paddingTop: ({ compact }) => (compact ? "1rem" : "5rem"),
    [theme.breakpoints.down("sm")]: { paddingTop: "3rem" },
    [theme.breakpoints.down("xs")]: { paddingTop: "2rem" },
    paddingBottom: ({ compact }) => (compact ? "1rem" : "5rem"),
    paddingLeft: ({ noHorizontalPadding }) =>
      noHorizontalPadding ? "0" : "1.5rem",
    paddingRight: ({ noHorizontalPadding }) =>
      noHorizontalPadding ? "0" : "1.5rem",
  },
  wrapper: {
    maxWidth: getMaxWidth,
    margin: "auto",
  },
});

const section = withStyles(styles)(({ className, classes, children }) => (
  <section className={classnames(classes.root, className)}>
    <div className={classes.wrapper}>{children}</div>
  </section>
));

section.propTypes = {
  children: any.isRequired,
  fullWidth: bool,
  compact: bool,
  narrow: bool,
  noHorizontalPadding: bool,
};

export default section;
