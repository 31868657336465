import { useContext } from "react";
import useMountEffect from "../../../hooks/useMountEffect";
import ajax from "../../../lib/ajax";
import SnackContext from "../../../contexts/snacks";
import { string } from "prop-types";
import { identity } from "lodash";

/**
 * Higher order component to output ajax errors using error snackbars.
 * @param defaultMessage {string} Default message to print if no message is found.
 * @param children {Element}
 * @return {*}
 */
const AjaxErrorInterceptor = ({ defaultMessage, children }) => {
  const { onSetError } = useContext(SnackContext);

  const onAjaxError = (error) => {
    const message = error.response
      ? error.response.data.message
      : "Unable to reach the server. Please retry later.";
    onSetError(message || defaultMessage);
    return Promise.reject(error);
  };

  useMountEffect(() => {
    ajax.interceptors.response.use(identity, onAjaxError);
  });

  return children;
};

AjaxErrorInterceptor.propTypes = {
  defaultMessage: string,
};

AjaxErrorInterceptor.defaultProps = {
  defaultMessage: "",
};

export default AjaxErrorInterceptor;
