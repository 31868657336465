import React from "react";
import Contact from "./Contact";
import Footer from "../Footer";
import Page from "../lib/hoc/Page";
import Helmet from "../lib/Helmet";
import useTranslate from "../../hooks/useTranslate";

const ContactPage = () => {
  const translate = useTranslate();
  return (
    <Page>
      <Helmet
        title={translate("ContactPage.title")}
        description={translate("ContactPage.description")}
        keywords={translate("ContactPage.keywords")}
        url={process.env.REACT_APP_LANDING_URL + "/faq"}
      />
      <Contact />
      <Footer />
    </Page>
  );
};

export default ContactPage;
