export default {
  noLimit: ["Unlimited", "Illimité"],
  comingSoon: ["Coming Soon", "Coming Soon"],
  title: ["Compare our plans", "Compare nos formules"],
  subtitle: ["Switch at any time", "Change à tout moment"],
  sections: {
    basic: {
      name: ["Basics", "Les bases"],
      items: {
        price: ["Monthly price", "Prix mensuel"],
        accountsCount: ["Social accounts", "Comptes sociaux"],
        maxScheduled: [
          "Scheduled posts per account",
          "Publications par compte",
        ],
        subAccounts: ["Sub-accounts (Teams)", "Sous-comptes (Teams)"],
      },
    },
    social: {
      name: ["Social networks", "Réseaux sociaux"],
    },
    planning: {
      name: ["Content Planning", "Programmation du contenu"],
      items: {
        postNow: ["Post now", "Publication instantanée"],
        schedule: ["Schedule posts", "Programmation de posts"],
        draft: ["Draft posts", "Brouillons"],
        calendarView: ["Calendar view", "Vue calendrier"],
        vizPlanner: ["Viz planner", "Viz planner"],
        instagramReelScheduling: [
          "Schedule Instagram reels (hack)",
          "Programmation de Reels Instagram (hack)",
        ],
      },
    },
    analytics: {
      name: ["Performance & Analytics", "Performance & Analytics"],
      items: {
        history: ["Post history", "Historique de publications"],
        postReports: ["Performance reports", "Rapports de performance"],
        csvReports: [
          "Download CSV/Excel reports",
          "Téléchargement des rapports CSV/Excel",
        ],
        pdfReports: ["Download PDF reports", "Téléchargement des rapports PDF"],
      },
    },
    integrations: {
      name: ["App and integrations", "Applis et intégrations"],
      items: {
        iOSApp: ["iOS app", "Appli iOS"],
      },
    },
    other: {
      name: ["Additional features", "Fonctionnalités additionelles"],
      items: {
        imageEditor: ["Image editor", "Éditeur photo"],
        hashtagManager: ["Hashtag manager", "Hashtag manager"],
        postApproval: ["Post approval", "Validation des publications"],
        commentManager: ["Engagement hub", "Engagement hub"],
      },
    },
    support: {
      name: ["Customer support", "Assistance client"],
      items: {
        email: ["E-mail support", "Support e-mail"],
        socialMedia: ["Social media support", "Support réseaux sociaux"],
        liveChat: ["Live Chat", "Chat"],
      },
    },
  },
  plans: {
    starter: {
      name: ["Starter", "Starter"],
    },
    partner: {
      name: ["Partner", "Partner"],
    },
    expert: {
      name: ["Expert", "Expert"],
    },
    master: {
      name: ["Master", "Master"],
    },
  },
};
