import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { darken, TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
  root: {
    backgroundColor: darken(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.contrastText,
    textAlign: "center",
  },
  group: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    "& div": {
      flexGrow: 1,
      maxWidth: 400,
      borderBottomLeftRadius: 5,
    },
    "& button": {
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: 56,
    },
  },
});

const NewsletterSignUp = withStyles(styles)(({ classes, form, onSubmit }) => (
  <Section className={classes.root} compact>
    <Typography variant="h5" paragraph>
      <Translate id="NewsletterSignUp.title" />
    </Typography>
    <form className={classes.group}>
      <TextField
        variant="filled"
        type="email"
        helperText={form.email.error || ""}
        value={form.email.value}
        onChange={(e) => form.email.setValue(e.currentTarget.value)}
        InputProps={{ disableUnderline: true }}
        label={<Translate id="global.email" />}
      />
      <Button variant="contained" color="default" onClick={onSubmit}>
        <Translate id="global.go" />
      </Button>
    </form>
  </Section>
));

export default NewsletterSignUp;
