import React from "react";
import { useTheme } from "@material-ui/core";
import useMountEffect from "../../../hooks/useMountEffect";

const CHAT_SELECTOR = ".fb_dialog.fb_dialog_mobile";
const CHAT_CHECK_SHOW_TIMEOUT = 500;

const onChatShowed = () => {
  try {
    const chatElement = document.querySelector(CHAT_SELECTOR);
    chatElement.style.zIndex = 900;
  } catch (e) {
    console.log(e);
  }
};

const waitForChatShowed = () => {
  try {
    if (!document.querySelector(CHAT_SELECTOR)) {
      setTimeout(waitForChatShowed, CHAT_CHECK_SHOW_TIMEOUT);
    } else {
      onChatShowed();
    }
  } catch (e) {
    console.log(e);
  }
};

const showChat = () => {
  // Defer the show action as the FB module is not loaded synchronously.
  setTimeout(() => {
    try {
      // Load plugin on demand only. There is no way to load it without
      // showing the chat.
      window.FB.XFBML.parse();
      // This does not seem to work, at least when showed after load.
      window.FB.Event.subscribe("customerchat.show", onChatShowed);
      // No hook to know when element is mounted, so perform regular
      // checks until it is the case.
      setTimeout(waitForChatShowed, CHAT_CHECK_SHOW_TIMEOUT);
    } catch (e) {
      console.log(e);
    }
  }, 1500);
};

const FBCustomerChat = () => {
  const theme = useTheme();
  useMountEffect(showChat);

  return (
    <div
      className="fb-customerchat"
      attribution="install_email"
      page_id="2763858073689326"
      theme_color={theme.palette.secondary.main}
      logged_in_greeting="Hi! How can we help you today?"
      logged_out_greeting="Hi! How can we help you today?"
    />
  );
};

export default FBCustomerChat;
