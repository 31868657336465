export default {
  title: [
    "bobcaat - the social media management platform for freelancers",
    "bobcaat - la plateforme de gestion réseaux sociaux pour freelances",
  ],
  description: [
    "bobcaat is an all-in-one social media management platform. Powered by Yellowstones, designed for freelancers.",
    "bobcaat est une plateforme tout-en-un. Créée par Yellowstones, pour les freelances.",
  ],
  keywords: [
    "bobcaat, yellowstones, social media, social media platform, social media tool, freelance, community management, freelancer",
    "bobcaat, yellowstones, réseaux sociaux, plateforme réseaux sociaux, outil réseaux sociaux, freelance, community management",
  ],
};
