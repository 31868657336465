import axios from "axios";

// Create our custom instance to use throughout the app.
const instance = axios.create();

instance.defaults.baseURL = process.env.REACT_APP_API_URL;
instance.defaults.headers.post["Content-Type"] = "application/json";

// Always include the token in storage if any.
instance.interceptors.request.use(
  (config) => {
    if (window.localStorage.getItem("appToken")) {
      config.headers["Authorization"] = localStorage.appToken;
    }
    if (window.localStorage.getItem("locale")) {
      config.headers["Accept-Language"] = localStorage.locale;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default instance;
