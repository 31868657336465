import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core";

/**
 * Tells whether the current screen size is at least the one specified.
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} bp
 * @return {boolean}
 */
export const useWidthUp = (bp) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up(bp));
};

/**
 * Tells whether the current screen size is below the one specified.
 * @param {"xs" | "sm" | "md" | "lg" | "xl"} bp
 * @return {boolean}
 */
export const useWidthDown = (bp) => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(bp));
};
