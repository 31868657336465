import { useState } from "react";
import { every, reduce, isEmpty } from "lodash";

const useForm = (initialState, onNewValue) => {
  const fieldNames = Object.keys(initialState);

  const BuildForm = (form, fieldName) => {
    const [value, setValueImpl] = useState(initialState[fieldName]);
    const [error, setError] = useState(null);
    const setValue = (val) => {
      setValueImpl(val);
      if (onNewValue) {
        onNewValue(fieldName, val);
      }
    };
    form[fieldName] = { value, setValue, error, setError };
    return form;
  };

  const collectForm = (result, fieldName) => {
    result[fieldName] = form[fieldName].value;
    return result;
  };

  const form = reduce(fieldNames, BuildForm, {});

  form.clear = () => {
    fieldNames.forEach((fieldName) => {
      form[fieldName].setValue(initialState[fieldName]);
    });
  };

  form.isValid = () =>
    every(
      fieldNames.map((fieldName) => form[fieldName].error),
      isEmpty
    );
  form.fields = () => fieldNames.map((fieldName) => form[fieldName]);
  form.collect = () => reduce(fieldNames, collectForm, {});

  return form;
};

export default useForm;
