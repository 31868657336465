import Menu from "@material-ui/core/Menu";
import React, { useState } from "react";

const usePopupMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const popupMenu = ({ children }) => (
    <Menu
      PaperProps={{
        style: {
          minWidth: 200,
        },
      }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
    >
      {children}
    </Menu>
  );
  return [
    popupMenu,
    (e) => setAnchorEl(e.currentTarget),
    () => setAnchorEl(null),
  ];
};
/**
 * Creates a popup menu to attach to a button.
 * Returns the React menu component, the onOpen function and the onClose one.
 * @return {[function({children: *}): *, function(*): void, function(): void]}
 */
export default usePopupMenu;
