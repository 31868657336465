import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { fade, Typography } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { isMobileSafari } from "../../../lib/platform";

const styles = (theme) => ({
  root: {
    textAlign: "center",
    backgroundColor: fade(theme.palette.secondary.main, 0.4),
  },
  player: {
    borderRadius: 5,
    outline: "none",
    marginTop: theme.spacing(2),
    margin: "auto",
    maxWidth: 900,
    width: "100%",
    boxShadow: theme.shadows[10],
  },
});

const VideoDemo = withStyles(styles)(({ classes }) => (
  <Section className={classes.root}>
    <Typography gutterBottom component="h3" color="inherit" variant="h4">
      <Translate id="VideoDemo.title" />
    </Typography>
    <Typography color="textSecondary" paragraph>
      <Translate id="VideoDemo.subtitle" />
    </Typography>
    <video
      controls
      preload="auto"
      playsInline
      muted={isMobileSafari}
      className={classes.player}
      src={`${process.env.REACT_APP_ATTACHMENTS_URL}/videos/bobcaat-demo.mp4`}
    />
  </Section>
));

VideoDemo.propTypes = {};

export default VideoDemo;
