import React, { useContext } from "react";
import SignInDialog from "./SignInDialog";
import { every } from "lodash";
import * as auth from "../../api/auth";
import SignInContext from "../../contexts/signin";
import useForm from "../../hooks/useForm";
import { checkEmail, checkPassword, validateField } from "../../lib/validation";
import SnackContext from "../../contexts/snacks";
import useTranslate from "../../hooks/useTranslate";

const SignInDialogContainer = () => {
  const { onCloseSignIn, open } = useContext(SignInContext);
  const { onSetSuccess } = useContext(SnackContext);
  const translate = useTranslate();
  const form = useForm({
    email: "",
    password: "",
  });

  const handleSubmit = () => {
    const isValid = every(
      [
        validateField(checkEmail, form.email),
        validateField(checkPassword, form.password),
      ],
      Boolean
    );
    if (isValid) {
      auth.signIn(form.collect());
    }
  };

  const handleResetPassword = () => {
    if (validateField(checkEmail, form.email)) {
      auth
        .requestPasswordReset({ username: form.email.value })
        .then(() =>
          onSetSuccess(translate("SignInDialog.resetPasswordRequestSent"))
        )
        .catch(console.log);
    }
  };

  return (
    <SignInDialog
      open={open}
      onClose={onCloseSignIn}
      onSubmit={handleSubmit}
      form={form}
      onResetPassword={handleResetPassword}
    />
  );
};

export default SignInDialogContainer;
