export default {
  title: ["FAQ", "FAQ"],
  subtitle: [
    "Find the answer to your question here. Or the question to your answer. Either way you like.",
    "Trouve la réponse à ta question ici. Ou la question à ta réponse. Comme tu veux !",
  ],
  content: {
    "plan-details": {
      label: ["What are your plans?", "Quelles sont vos formules ?"],
      answer: [
        `Bobcaat currently has four plans:
         <ul>
            <li>Starter: ideal for those with a small client base</li>
            <li>Partner: for those who need more advanced features than the Starter plan</li>
            <li>Expert: more social accounts and advanced features, with unlimited posts</li>
            <li>Master: total freedom with unlimited social accounts, unlimited posts and all our features</li>
         </ul>
         <br/>
         Find more about our plans <a href="/plans">here</a>.`,
        `bobcaat propose actuellement quatre formules
         <ul>
            <li>Starter: idéale pour les petites clientèles</li>
            <li>Partner: plus de comptes et fonctionnalités plus avancées que la formule Starter</li>
            <li>Expert: encore plus de comptes et fonctionnalités avancées, avec posts illimités</li>
            <li>Master: liberté totale avec comptes et posts illimités, et accès à toutes nos fonctionnalités</li>
         </ul>
         <br/>
         Tous les détails de nos formules <a href="/plans">ici</a>.`,
      ],
    },
    "corporate-plans": {
      label: [
        "Have you got plans for companies and agencies?",
        "Proposez-vous des formules pour les entreprises et agences ?",
      ],
      answer: [
        `A corporate plan may be available sometime in the future,
        but we are choosing to focus on freelancers for now!
        However, our existing plans may certainly suit your needs.
        Feel free to look at our plans and start your 30-day free trial.`,
        "Cela est envisageable, mais nous préférons nous concentrer sur les besoins freelances pour le moment ! Cela dit, nos formules existantes peuvent tout à fait correspondre à tes besoins. N'hésite pas à y jeter un oeil et démarrer ton essai gratuit de 30 jours.",
      ],
    },
    "which-plan": {
      label: [
        "Which plan should I choose?",
        "Quelle formule devrais-je choisir ?",
      ],
      answer: [
        `The plan you choose depends on your needs: how many social accounts
         do you manage? Which features are necessary to make your work easier? We often
         recommend the Partner plan as it boasts many features for a
         relatively low price. If you are still unsure after having looked
         at <a href="/plans">our different plans</a>, feel free to <a href="/contact">
         get in touch</a> so we can guide you more personally.`,
        `La formule la plus adaptée dépend de tes besoins : combien de comptes 
         gères-tu sur les réseaux sociaux ? De quelles fonctionnalités as-tu 
         besoin pour faciliter ton travail ?  
         Nous recommandons souvent la formule Partner car elle offre de nombreuses fonctionnalités à moindre coût. 
         Si tu n'es toujours pas sûr(e) après avoir consulté <a href="/plans">nos offres</a>, n'hésite pas à <a href="/contact">nous contacter</a> afin que nous puissions t'orienter plus personnellement.`,
      ],
    },
    "free-plan": {
      label: [
        "Why don’t you offer a free plan?",
        "Pourquoi ne proposez-vous pas de formule gratuite ?",
      ],
      answer: [
        `We know several platforms offer free plans, but this goes against our
        values. We created bobcaat to support freelancers by providing them
        with an affordable platform that offers many features. However, we do
        not encourage anyone to sell their services for free or below market
        value – and that includes us!<br/>
        You should also be aware that when a product is free, this usually means the
        product is you. At bobcaat, we sell a platform, not hard-working freelancers!`,
        `Plusieurs plateformes proposent effectivement des formules gratuites, mais 
        cela va à l'encontre de nos valeurs. Nous avons créé bobcaat pour soutenir les 
        freelances en leur fournissant une plateforme abordable offrant de nombreuses 
        fonctionnalités. Cependant, nous n'encourageons personne à vendre ses services 
        gratuitement ou en dessous de leur valeur marchande - et cela va pour bobcaat 
        également !<br/>
        Tu sais sans doute que lorsqu'un produit est gratuit, cela signifie 
        généralement que le produit… c’est toi !`,
      ],
    },
    "change-plans": {
      label: ["Can I change plans?", "Puis-je changer de formule ?"],
      answer: [
        `Absolutely! We understand that as freelancers, a client base can vary
      from one month to the next. This is why bobcaat is completely
      flexible and lets you switch plans each month, as long as you do so
      before your billing date which you can find in the Plan section of
      your account settings.`,
        `Absolument ! Nous comprenons qu'en tant qu'indépendant, une clientèle peut 
        varier d'un mois à l'autre. C'est pourquoi bobcaat est totalement flexible et 
        te permet de changer de forfait chaque mois, à condition de le faire avant ta 
        date de renouvellement (que tu peux trouver dans la section Formule de tes 
        paramètres de compte).`,
      ],
    },
    "free-trial": {
      label: [
        "Can I try all four plans for free?",
        "Puis-je essayer les quatre formules gratuitement ?",
      ],
      answer: [
        `Our 30-day free trial is only available once. Regardless of the plan you choose,
        the free trial gives you access to the Master plan so you can experience bobcaat at its fullest.
        This should also help you choose the plan that is right for you by filtering out features you may not need.`,
        `Notre essai gratuit de 30 jours n'est disponible qu'une seule fois. En revanche, 
        quel que soit le plan que tu choisis, l'essai gratuit te donne accès à la formule
         Master afin que tu puisses profiter de la version intégrale de bobcaat. 
         Cela devrait également t’aider à choisir la formule qui te convient le mieux, en filtrant les fonctionnalités
          dont tu n'as peut-être pas besoin.`,
      ],
    },
    "change-details": {
      label: [
        "How can I change my personal details?",
        "Comment puis-je modifier mes données personnelles ?",
      ],
      answer: [
        "To change your personal details, click on your avatar" +
          " in the application bar. This will open your settings and account " +
          "information. Make all your necessary changes and click “save” on " +
          "the top right corner.",
        `Pour modifier tes informations personnelles, clique sur ton avatar dans la 
        barre de menu. Cela ouvrira tes paramètres et tes informations de compte. 
        Une fois les modifications nécessaires effectuées, clique sur «Enregistrer» 
        en haut à droite.`,
      ],
    },
    "delete-account": {
      label: ["How can I delete my account?", "Comment supprimer mon compte ?"],
      answer: [
        "To delete your account, click on your avatar in the" +
          " application bar. Scroll down your settings and account information" +
          " and click “Delete my account”.",
        "Pour supprimer ton compte, clique sur ton avatar dans la barre de menu. Défile " +
          "tes paramètres et informations de compte et clique sur «Supprimer mon compte».",
      ],
    },
    "portfolio-definition": {
      label: ["What is a portfolio?", "Qu'est-ce qu'un portfolio ?"],
      answer: [
        `A portfolio is an account you create for a specific client.
        You can then connect several social media accounts. For instance,
        if your client is Joanne’s bakery, you can create a portfolio named
        “Joanne’s bakery” and then connect its social channels such as Facebook and Instagram.
        Portfolios are a great way of separating your various clients so you can organise your workspace better!`,
        `Un portfolio est un compte que tu crées pour un client spécifique. Tu peux ensuite 
        y associer plusieurs comptes réseaux sociaux (un par plateforme). 
        Par exemple, si ton client est «la boulangerie de Ginette», tu dois créer un 
        portfolio appelé «Boulangerie de Ginette», puis y connecter ses comptes Facebook,
        Instagram, etc… Les portfolios sont un excellent moyen de séparer tes différents 
        clients afin de mieux organiser ton espace de travail !`,
      ],
    },
    "portfolio-edit": {
      label: [
        "How do I edit my portfolio information?",
        "Comment modifier les informations de mon portfolio ?",
      ],
      answer: [
        `To edit your portfolio information, click on your portfolio’s “Settings” button then “Edit”.
        Once you’ve finished editing your fields, don’t forget to save your changes.`,
        `Pour modifier les informations de ton portfolio, clique sur le bouton «Paramètres» de ton portfolio puis sur «Modifier». Une fois les informations modifiées, n'oublie pas d'enregistrer.`,
      ],
    },
    "portfolio-account-count": {
      label: [
        "How many social media accounts can I add to each portfolio?",
        "Combien de comptes réseaux sociaux puis-je ajouter à chaque portfolio ?",
      ],
      answer: [
        `Currently, you can add one social account per social network which means each portfolio
        can have up to 4 social accounts: one for Facebook, one for Instagram,
        one for LinkedIn and one for Twitter. However, you can post on as many
        Facebook groups as you want, directly from the Editor.`,
        `Actuellement, tu peux connecter un compte par réseau social, ce qui signifie que 
        chaque portfolio peut avoir jusqu'à 4 comptes sociaux (Facebook, Instagram, LinkedIn et Twitter). 
        Cependant, il est possible de publier dans autant de groupes Facebook que nécessaire, directement via l’éditeur.`,
      ],
    },
    "portfolio-count": {
      label: [
        "How many portfolios can I create?",
        "Combien de portfolios puis-je créer ?",
      ],
      answer: [
        `You can create as many portfolios as you wish. The only limits apply to
        social media accounts and posts - unless you have the Master plan,
        in which case everything is unlimited!`,
        `Tu peux créer autant de portfolios que tu le souhaites. Les seules limites 
        sont celles du nombre de comptes ou publications - sauf si tu as la formule 
        Master, qui est 100% illimitée !`,
      ],
    },
    "portfolio-delete": {
      label: [
        "How do I delete a portfolio?",
        "Comment supprimer un portfolio ?",
      ],
      answer: [
        `To edit your portfolio information, click on your portfolio’s “Settings” button then “Edit”`,
        `Pour supprimer ton portfolio, clique sur le bouton «Paramètres» de ton portfolio puis sur «Supprimer».`,
      ],
    },
    "social-networks": {
      label: [
        "Which social networks are available on bobcaat?",
        "Quels sont les réseaux sociaux disponibles sur bobcaat ?",
      ],
      answer: [
        `The social networks you can currently manage with bobcaat are
         Facebook, Instagram, LinkedIn, Twitter, Pinterest & Google my Business. Others are coming soon…
         Feel free to <a href="/contact">let us know</a> if you have any requests.`,
        `Les réseaux sociaux disponibles sur bobcaat sont Facebook, Instagram, LinkedIn, Twitter, Pinterest &  Google my Business. D’autres sont dans les tuyaux. N'hésite pas à <a href="/contact">nous faire part de 
        tes demandes.</a>`,
      ],
    },
    "account-delete": {
      label: [
        "How do I delete a social media account?",
        "Comment déconnecter un compte de mon portfolio ?",
      ],
      answer: [
        `To disconnect an account, click on the pencil icon of the social network you wish to delete, then click "Disconnect".`,
        `Pour déconnecter un compte, clique sur l’icône crayon du réseau social de ton 
        portfolio, puis sur «Déconnecter».`,
      ],
    },
    "mobile-app": {
      label: [
        "Is a bobcaat mobile app available?",
        "Une application mobile bobcaat est-elle disponible ?",
      ],
      answer: [
        "Our mobile app is now available for iPhones and iPads, " +
          "Android users will have to wait a little longer! We will let you " +
          "know when our Android app is available on social media or via our newsletter.",
        `Notre application mobile est désormais disponible pour les iPhones et iPads. 
        Les utilisateurs Android devront patienter encore un peu ! Tu peux trouver toutes 
        nos annonces sur nos réseaux sociaux ou en t’inscrivant à notre newsletter.`,
      ],
    },
  },
};
