import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const tiktokIcon = React.forwardRef(({ htmlColor, ...props }, ref) => (
  <SvgIcon
    ref={ref}
    titleAccess="Tiktok"
    {...props}
    htmlColor={htmlColor || "inherit"}
    focusable="false"
    aria-hidden="true"
    data-icon="tiktok"
    viewBox="4 4 42 42"
  >
    <path d="M41 4H9C6.243 4 4 6.243 4 9v32c0 2.757 2.243 5 5 5h32c2.757 0 5-2.243 5-5V9c0-2.757-2.243-5-5-5m-3.994 18.323a7.482 7.482 0 0 1-.69.035 7.492 7.492 0 0 1-6.269-3.388v11.537a8.527 8.527 0 1 1-8.527-8.527c.178 0 .352.016.527.027v4.202c-.175-.021-.347-.053-.527-.053a4.351 4.351 0 1 0 0 8.704c2.404 0 4.527-1.894 4.527-4.298l.042-19.594h4.02a7.488 7.488 0 0 0 6.901 6.685v4.67" />
  </SvgIcon>
));

export default tiktokIcon;
