export default {
  title: [
    "Packed with the features you need and nothing more. <br/>" +
      "Simplicity at your fingertips. ",
    "Toutes les fonctionnalités dont tu as besoin sans superflu. <br/>" +
      "La simplicité à portée de main",
  ],
  features: {
    editor: {
      name: ["Post creator", "Éditeur"],
      title: [
        "Create content in one go, like you would on social networks.",
        "Crée ton contenu comme sur les réseaux sociaux.",
      ],
      description: [
        "Post now, schedule for later or save in your drafts folder.",
        "Publie maintenant, programme pour plus tard ou enregistre en tant que brouillon.",
      ],
    },
    calendar: {
      name: ["Calendar", "Agenda"],
      title: [
        "View and rearrange your posts.",
        "Affiche et réorganise ton planning.",
      ],
      description: [
        "No more spreadsheets or calendar apps. Finally, a real social media planner!",
        "Fini les tableurs et appli calendriers. Enfin un vrai calendrier réseaux sociaux !",
      ],
    },
    hashtags: {
      name: ["Hashtags", "Hashtags"],
      title: [
        "Boost your content with your favourite hashtags.",
        "Booste tes contenus avec tes hashtags préférés",
      ],
      description: [
        "Often use the same hashtags? Save them in groups!",
        "Tu utilises souvent (ou toujours) les mêmes hashtags ? Enregistre-les dans des groupes !",
      ],
    },
    dashboard: {
      name: ["Dashboard", "Tableau de bord"],
      title: [
        "All your clients' accounts in one single place.",
        "Tous tes comptes clients au même endroit.",
      ],
      description: [
        "An overview of all your connected accounts at once.",
        "Un aperçu de tous tes comptes réseaux sociaux sur la même interface.",
      ],
    },
    analytics: {
      name: ["Analytics", "Statistiques"],
      title: [
        "Measure how your content performs.",
        "Mesure la performance de tes contenus.",
      ],
      description: [
        "And report to your client with Excel or CSV exports.",
        "Et exporte ton activité au format Excel ou CSV pour rapporter à tes clients.",
      ],
    },
    engagement: {
      name: ["Engagement", "Engagement"],
      title: [
        "Like and react to your audience's comments.",
        "Like et réagis aux commentaires de ton public.",
      ],
      description: [
        "You are notified whenever someone comments on your posts created with bobcaat.",
        "Tu es notifié à chaque fois que quelqu'un commente sur l'une de tes publications créees avec bobcaat.",
      ],
    },
  },
};
