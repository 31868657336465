import React, { useState } from "react";
import { noop } from "lodash";
import Snackbar from "../components/lib/Snackbar";
import Portal from "@material-ui/core/Portal";

const SnackContext = React.createContext({
  onSetInfo: noop,
  onSetSuccess: noop,
  onSetError: noop,
});

/**
 *
 * @param setter {function({open:boolean, msg:string})}
 * @return {function(string)}
 */
const handleSet = (setter) => (msg) => setter({ open: true, msg });
/**
 *
 * @param setter {function}
 * @return {function(): *}
 */
const handleClose = (setter) => () =>
  setter((state) => ({ ...state, open: false }));

/**
 * Used only once to wrap the whole app with a snackbar context and behaviour.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const SnackProvider = ({ children }) => {
  const [info, setInfo] = useState({ open: false, msg: "" });
  const [success, setSuccess] = useState({ open: false, msg: "" });
  const [error, setError] = useState({ open: false, msg: "" });

  const context = {
    onSetInfo: handleSet(setInfo),
    onSetSuccess: handleSet(setSuccess),
    onSetError: handleSet(setError),
  };
  return (
    <SnackContext.Provider value={context}>
      <Portal>
        <Snackbar
          onClose={handleClose(setInfo)}
          message={info.msg}
          open={info.open}
          variant="info"
        />
        <Snackbar
          onClose={handleClose(setSuccess)}
          message={success.msg}
          open={success.open}
          variant="success"
        />
        <Snackbar
          onClose={handleClose(setError)}
          message={error.msg}
          open={error.open}
          variant="error"
        />
      </Portal>
      {children}
    </SnackContext.Provider>
  );
};

export default SnackContext;
