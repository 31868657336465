import React, { useState } from "react";
import { isEmpty, noop } from "lodash";
import useMountEffect from "../hooks/useMountEffect";
import { getInvite } from "../api/misc";
import { applyIfTruthy } from "../lib/func";

const SignUpContext = React.createContext({
  onOpenSignUp: noop,
  onCloseSignUp: noop,
  open: false,
});

/**
 * Load any invite that might be passed through an URL.
 * @param url {URL} the URL to check.
 * @return {Promise<boolean>} True if signup dialog should be opened as a result.
 */
const checkInviteIn = async (url) => {
  const code = url.searchParams.get("invite");
  if (!code) {
    return false;
  }
  const invite = await getInvite(code).data;
  if (isEmpty(invite)) {
    return false;
  }

  window.sessionStorage.setItem("invite", JSON.stringify({ code, ...invite }));
  return true;
};

/**
 * Used only once to wrap the whole app with a signUp state context.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const SignUpContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const setOpenIfTrue = applyIfTruthy(setOpen);

  // Check query params for state at mounting.
  useMountEffect(() => {
    const url = new URL(window.location.href);
    checkInviteIn(url).then(setOpenIfTrue);
    setOpenIfTrue(url.searchParams.get("action") === "signup");
  });

  const context = {
    onOpenSignUp: () => setOpen(true),
    onCloseSignUp: () => setOpen(false),
    open,
  };

  return (
    <SignUpContext.Provider value={context}>{children}</SignUpContext.Provider>
  );
};

export default SignUpContext;
