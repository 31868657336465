export default {
  query: ["I have a general query", "J'ai une question d'ordre général"],
  complaint: ["I would like to make a complaint", "Je voudrais me plaindre"],
  business: [
    "I have a business related question",
    "J'ai une question business",
  ],
  suggestion: [
    "I would like to make a suggestion",
    "J'aimerais faire une suggestion",
  ],
  tech: [
    "I am in need of technical support",
    "J'ai besoin de support technique",
  ],
  press: ["I need some press information", "Je suis de la presse"],
  other: ["Other", "Autre"],
};
