import LandingPage from "./components/LandingPage";
import FAQPage from "./components/FAQPage";
import ContactPage from "./components/ContactPage";
import TermsOfServicePage from "./components/TermsOfServicePage";
import AboutPage from "./components/AboutPage";
import PricingPage from "./components/PricingPage";

const routes = [
  { href: "/", exact: true, label: "routes.home", Component: LandingPage },
  { href: "/about", exact: false, label: "routes.about", Component: AboutPage },
  { href: "/faq", exact: false, label: "routes.faq", Component: FAQPage },
  {
    href: "/contact",
    exact: false,
    label: "routes.contact",
    Component: ContactPage,
  },
  {
    href: "/plans",
    exact: false,
    label: "routes.pricing",
    Component: PricingPage,
  },
  {
    href: "/terms-of-service",
    exact: true,
    hidden: true,
    label: "routes.termsOfService",
    Component: TermsOfServicePage,
  },
];
export default routes;
