import { format as formatCurrency } from "./currencies";

const PRICES = {
  GBP: { starter: 5, partner: 15, expert: 30, master: 50 },
  EUR: { starter: 6, partner: 18, expert: 35, master: 60 },
  USD: { starter: 7, partner: 20, expert: 40, master: 70 },
};
export default PRICES;

export const getPrice = (plan, cur) => formatCurrency(cur, PRICES[cur][plan]);
