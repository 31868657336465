import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import faq from "../../../refer/faqQuestions";
import Section from "../../lib/hoc/Section";
import { Translate } from "react-localize-redux";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const styles = (theme) => ({
  root: {
    paddingTop: theme.metrics.appBarHeight + theme.spacing(6),
    maxWidth: 1000,
    margin: "auto",
  },
  content: {
    marginTop: theme.spacing(4),
  },
  question: {
    backgroundColor: theme.palette.background.default,
  },
  answer: {
    maxWidth: 800,
  },
});

const FAQ = withStyles(styles)(({ classes }) => (
  <Section className={classes.root}>
    <Typography variant="h3" component="h1" gutterBottom>
      <Translate id="FAQ.title" />
    </Typography>
    <Typography variant="body1" color="textSecondary">
      <Translate id="FAQ.subtitle" />
    </Typography>
    <div className={classes.content}>
      {faq.map(({ path, label, answer }) => (
        <Accordion key={path} elevation={0} className={classes.question}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={path + "-header"}
          >
            <Typography component="h3" variant="h6" color="primary">
              <Translate id={label} />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography component="div" className={classes.answer}>
              <Translate id={answer} options={{ renderInnerHtml: true }} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  </Section>
));

FAQ.propTypes = {};

export default FAQ;
