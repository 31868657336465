import React from "react";
import { fade, Link, Typography, withStyles } from "@material-ui/core";
import aboutImg from "../../../assets/images/about.jpg";
import channels from "../../../refer/contactChannels";
import Section from "../../lib/hoc/Section";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
  root: {
    "& strong": {
      color: theme.palette.primary.main,
    },
  },
  titleSection: {
    paddingTop: theme.metrics.appBarHeight,
    backgroundImage: `url('${aboutImg}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
    position: "relative",
    minHeight: 500,
  },
  overlay: {
    position: "absolute",
    zIndex: 0,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: fade(theme.palette.secondary.main, 0.9),
  },
  slogan: {
    transition: "font-size 0.2s ease",
    lineHeight: 1.5,
    position: "relative",
    sIndex: 1,
    paddingTop: theme.spacing(4),
    margin: "auto",
    maxWidth: "800px",
  },
  feedbackSection: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
  },
  feedbackSectionContent: {
    lineHeight: 1.18,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      margin: 0,
      height: "100%",
    },
  },
  aboutLink: {
    textAlign: "center",
    display: "inline-block",
    "& img": {
      marginTop: "2rem",
      width: "100%",
    },
  },
  socialIcons: {
    textAlign: "center",
    marginTop: theme.spacing(3),
    color: theme.palette.common.white,
    "& a": {
      marginRight: theme.spacing(4),
      display: "inline-block",
      transition: "color 0.3s ease",
      "&:hover": {
        animation: `${theme.animations.beat} 0.6s`,
      },
    },
  },
  facebook: {
    "&:hover": {
      color: "rgb(59,88,149)",
    },
  },
  instagram: {
    "&:hover": {
      color: "rgb(187,0,104)",
    },
  },
  linkedin: {
    "&:hover": {
      color: "rgb(13,100,149)",
    },
  },
  twitter: {
    "&:hover": {
      color: "#2AA3EF",
    },
  },
  email: {
    "&:hover": {
      color: "#FFFFFF",
    },
  },
});

const about = withStyles(styles)(({ classes }) => (
  <div className={classes.root}>
    <Section className={classes.titleSection}>
      <div className={classes.overlay} />
      <Typography
        className={classes.slogan}
        component="h1"
        variant="h3"
        gutterBottom
      >
        <Translate id="About.title" options={{ renderInnerHtml: true }} />
      </Typography>
    </Section>
    <Section narrow>
      <Typography variant="h4" component="h2">
        <Translate id="About.subtitle" />
      </Typography>
      <Typography variant="h4" component="h2" gutterBottom>
        <Translate id="About.missionTitle" />
      </Typography>
      <Typography variant="body1" paragraph>
        <Translate id="About.missionBody" options={{ renderInnerHtml: true }} />
      </Typography>
    </Section>
    <Section className={classes.feedbackSection} narrow>
      <Typography variant="h5">
        <div className={classes.feedbackSectionContent}>
          <span>
            <Translate id="About.feedbackWelcomed" />
          </span>
          <Typography
            variant="h3"
            component="p"
            className={classes.socialIcons}
          >
            {channels.map(({ className, name, ...props }) => (
              <Link
                {...props}
                color="inherit"
                className={classes[name]}
                key={name}
                rel="noopener noreferrer"
              >
                <i className={className} />
              </Link>
            ))}
          </Typography>
        </div>
      </Typography>
    </Section>
    <Section narrow>
      <Typography variant="h4" component="h2" gutterBottom>
        <Translate
          id="About.introducingBobcaatTitle"
          options={{ renderInnerHtml: true }}
        />
      </Typography>
      <Typography variant="body1" paragraph>
        <Translate
          id="About.introducingBobcaatBody"
          options={{ renderInnerHtml: true }}
        />
      </Typography>
    </Section>
  </div>
));

export default about;
