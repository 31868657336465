export default {
  sectionNavigation: ["Navigation", "Navigation"],
  sectionLegal: ["Legal", "Légal"],
  faq: ["FAQ", "FAQ"],
  pricing: ["Pricing", "Tarifs"],
  contact: ["Contact", "Contact"],
  termsOfService: ["Terms of service", "Conditions d'utilisation"],
  contactDetails: [
    "From our Brighton office, BN1 4QB.",
    "Depuis nos locaux à Brighton, BN1 4QB.",
  ],
};
