import React from "react";
import { bool, func, number, oneOf, shape, string } from "prop-types";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import { amber, blue, green } from "@material-ui/core/colors";
import { SnackbarContent, withStyles } from "@material-ui/core";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = (theme) => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: blue[600],
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  closeIcon: {
    fontSize: 20,
  },
  message: {
    display: "flex",
    alignItems: "center",
  },
});

const CloseButton = ({ classes, onClose }) => (
  <IconButton
    aria-label="Close"
    color="inherit"
    className={classes.closeIcon}
    onClick={onClose}
  >
    <CloseIcon />
  </IconButton>
);

const Content = ({ classes, message, variant }) => {
  const Icon = variantIcon[variant];
  return (
    <span className={classes.message}>
      <Icon className={classes.icon} /> {message}
    </span>
  );
};

const snackbar = withStyles(styles)(
  ({ message, variant, open, onClose, duration, anchor, classes }) => (
    <Snackbar
      anchorOrigin={anchor}
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[variant]}
        message={
          <Content classes={classes} variant={variant} message={message} />
        }
        action={[
          <CloseButton key="close" classes={classes} onClose={onClose} />,
        ]}
      />
    </Snackbar>
  )
);

snackbar.propTypes = {
  message: string,
  variant: oneOf(["info", "warning", "error", "success"]),
  onClose: func,
  anchor: shape({
    vertical: oneOf(["top", "bottom"]),
    horizontal: oneOf(["left", "right"]),
  }),
  open: bool,
  duration: number,
};

snackbar.defaultProps = {
  variant: "info",
  anchor: { vertical: "top", horizontal: "right" },
  duration: 6000,
};

export default snackbar;
