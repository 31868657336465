import global from "./global";
import LandingPage from "./LandingPage";
import AppBar from "./AppBar.json";
import routes from "./routes";
import CookieWarning from "./CookieWarning.json";
import Footer from "./Footer";
import Jumbo from "./Jumbo";
import CTA from "./CTA.json";
import Guarantees from "./Guarantees";
import SocialHighlights from "./SocialHighlights";
import NewsletterSignUp from "./NewsletterSignUp";
import VideoDemo from "./VideoDemo";
import Features from "./Features";
import Benefits from "./Benefits.json";
import FAQPage from "./FAQPage";
import FAQ from "./FAQ";
import Contact from "./Contact.json";
import supportCategories from "./supportCategories";
import TermsOfServicePage from "./TermsOfServicePage";
import TermsOfService from "./TermsOfService";
import ContactPage from "./ContactPage.json";
import AboutPage from "./AboutPage.json";
import About from "./About.json";
import PricingPage from "./PricingPage";
import Pricing from "./Pricing";
import validation from "./validation";
import SignUpDialog from "./SignUpDialog";
import SignInDialog from "./SignInDialog";
import signupReasons from "./signupReasons";

export default {
  global,
  LandingPage,
  FAQPage,
  TermsOfServicePage,
  TermsOfService,
  CookieWarning,
  AppBar,
  routes,
  Footer,
  Jumbo,
  CTA,
  Guarantees,
  SocialHighlights,
  NewsletterSignUp,
  VideoDemo,
  Features,
  Benefits,
  FAQ,
  Contact,
  ContactPage,
  AboutPage,
  About,
  supportCategories,
  PricingPage,
  Pricing,
  SignUpDialog,
  SignInDialog,
  validation,
  signupReasons,
};
