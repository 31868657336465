import React, { useContext } from "react";
import { Divider, Paper, Typography, withStyles } from "@material-ui/core";
import Table from "./Table";
import IconYes from "@material-ui/icons/Check";
import IconNo from "@material-ui/icons/Close";
import currencies from "../../../refer/currencies";
import starterThumb from "../../../assets/images/plans/bobcaat-starter.png";
import partnerThumb from "../../../assets/images/plans/bobcaat-partner.png";
import expertThumb from "../../../assets/images/plans/bobcaat-expert.png";
import masterThumb from "../../../assets/images/plans/bobcaat-master.png";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { Translate } from "react-localize-redux";
import Section from "../../lib/hoc/Section";
import { getPrice } from "../../../refer/prices";
import CurrencyContext from "../../../contexts/currency";

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(4),
    paddingTop: theme.metrics.appBarHeight,
    textAlign: "center",
  },
  sectionTitle: {
    margin: theme.spacing(1),
  },
  currencies: {
    textAlign: "center",
  },
});
const createData = (name, starter, partner, expert, master) => {
  return {
    id: name,
    name: <Translate id={name} />,
    starter,
    partner,
    expert,
    master,
  };
};

const makeColumn = (id, thumb, isMain = false) => ({
  id,
  label: <Translate id={`Pricing.plans.${id}.name`} />,
  thumb,
  width: "17%",
  isMain,
});

const columns = () => [
  { id: "name", label: "", width: "32%", isMain: false },
  makeColumn("starter", starterThumb),
  makeColumn("partner", partnerThumb, true),
  makeColumn("expert", expertThumb),
  makeColumn("master", masterThumb),
];

const YES = <IconYes color="primary" />;
const NO = <IconNo color="error" />;
const UNLIMITED = <Translate id="Pricing.noLimit" />;

const SECTIONS = (currency) => [
  {
    key: "header",
    name: "",
    feats: [],
  },
  {
    key: "basic",
    name: <Translate id="Pricing.sections.basic.name" />,
    feats: [
      createData(
        "Pricing.sections.basic.items.price",
        getPrice("starter", currency),
        getPrice("partner", currency),
        getPrice("expert", currency),
        getPrice("master", currency)
      ),
      createData(
        "Pricing.sections.basic.items.accountsCount",
        5,
        20,
        50,
        UNLIMITED
      ),
      createData(
        "Pricing.sections.basic.items.maxScheduled",
        30,
        250,
        UNLIMITED,
        UNLIMITED
      ),
      createData("Pricing.sections.basic.items.subAccounts", NO, NO, 5, 10),
    ],
  },
  {
    key: "social",
    name: <Translate id="Pricing.sections.social.name" />,
    feats: [
      createData("Facebook", YES, YES, YES, YES),
      createData("Twitter", YES, YES, YES, YES),
      createData("Linkedin", YES, YES, YES, YES),
      createData("Instagram", YES, YES, YES, YES),
      createData("Pinterest", YES, YES, YES, YES),
      createData("Google My Business", YES, YES, YES, YES),
      createData("Tiktok", YES, YES, YES, YES),
    ],
  },
  {
    key: "planning",
    name: <Translate id="Pricing.sections.planning.name" />,
    feats: [
      createData("Pricing.sections.planning.items.postNow", YES, YES, YES, YES),
      createData(
        "Pricing.sections.planning.items.schedule",
        YES,
        YES,
        YES,
        YES
      ),
      createData("Pricing.sections.planning.items.draft", YES, YES, YES, YES),
      createData(
        "Pricing.sections.planning.items.calendarView",
        YES,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.planning.items.instagramReelScheduling",
        YES,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.planning.items.vizPlanner",
        NO,
        YES,
        YES,
        YES
      ),
    ],
  },
  {
    key: "analytics",
    name: <Translate id="Pricing.sections.analytics.name" />,
    feats: [
      createData(
        "Pricing.sections.analytics.items.history",
        YES,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.analytics.items.postReports",
        NO,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.analytics.items.csvReports",
        NO,
        YES,
        YES,
        YES
      ),
    ],
  },
  {
    key: "integrations",
    name: <Translate id="Pricing.sections.integrations.name" />,
    feats: [
      createData(
        "Pricing.sections.integrations.items.iOSApp",
        YES,
        YES,
        YES,
        YES
      ),
    ],
  },
  {
    key: "other",
    name: <Translate id="Pricing.sections.other.name" />,
    feats: [
      createData(
        "Pricing.sections.other.items.imageEditor",
        YES,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.other.items.hashtagManager",
        NO,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.other.items.postApproval",
        NO,
        YES,
        YES,
        YES
      ),
      createData(
        "Pricing.sections.other.items.commentManager",
        NO,
        NO,
        YES,
        YES
      ),
    ],
  },
  {
    key: "support",
    name: <Translate id="Pricing.sections.support.name" />,
    feats: [
      createData("Pricing.sections.support.items.email", YES, YES, YES, YES),
      createData(
        "Pricing.sections.support.items.socialMedia",
        YES,
        YES,
        YES,
        YES
      ),
      createData("Pricing.sections.support.items.liveChat", YES, YES, YES, YES),
    ],
  },
];

const Pricing = withStyles(styles)(({ classes }) => {
  const { currency, onSetCurrency } = useContext(CurrencyContext);
  return (
    <Section noHorizontalPadding>
      <div className={classes.title}>
        <Typography variant="h3" paragraph>
          <Translate id="Pricing.title" />
        </Typography>
        <Typography gutterBottom>
          <Translate id="Pricing.subtitle" />
        </Typography>
      </div>
      <div className={classes.currencies}>
        <ButtonGroup>
          {currencies.map(({ name }) => (
            <Button
              variant="contained"
              key={name}
              color={name === currency ? "secondary" : "default"}
              onClick={() => onSetCurrency(name)}
            >
              {name}
            </Button>
          ))}
        </ButtonGroup>
      </div>
      <Paper className={classes.paper}>
        {SECTIONS(currency).map(({ key, feats, name }, index) => (
          <React.Fragment key={key}>
            <Typography
              variant="h5"
              component="h2"
              gutterBottom
              className={classes.sectionTitle}
            >
              {name}
            </Typography>
            <Divider />
            <Table columns={columns()} rows={feats} noHeader={index > 0} />
          </React.Fragment>
        ))}
      </Paper>
    </Section>
  );
});

export default Pricing;
