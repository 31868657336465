import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { bool, func, object, string } from "prop-types";
import AccountIcon from "@material-ui/icons/AccountBox";
import { Checkbox, Hidden, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import logo from "../../assets/images/bobcaat_white.png";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Link from "@material-ui/core/Link";
import { useWidthDown } from "../../util/breakpoints";
import { Translate } from "react-localize-redux";
import Select from "../lib/Select";
import useTranslate from "../../hooks/useTranslate";
import signupReasons from "../../suggestions/signupReasons";

const styles = (theme) => ({
  splash: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  root: {
    padding: theme.spacing(2),
    height: "100%",
    overflowY: "auto",
    [theme.breakpoints.down("xs")]: { padding: 0 },
  },
  logo: {
    width: "100px",
  },
  form: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: { marginBottom: theme.spacing(2) },
  },
});

const signUpDialog = withStyles(styles)(
  ({
    open,
    onClose,
    onSubmit,
    referredBy,
    form,
    showExplainSignupReason,
    classes,
  }) => {
    const isMobile = useWidthDown("xs");
    const translate = useTranslate();

    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        onSubmit();
      }
    };

    return (
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullScreen={isMobile}
        aria-labelledby="form-dialog-title"
      >
        <Grid container style={{ height: "100%" }}>
          <Grid item xs={12} sm={7}>
            <div className={classes.root}>
              <DialogTitle id="form-dialog-title" disableTypography>
                <Typography component="div" align="center">
                  <AccountIcon color="secondary" fontSize="large" />
                </Typography>
                <Typography component="h3" variant="h6" align="center">
                  <Translate id="SignUpDialog.title" />
                </Typography>
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <Translate id="SignUpDialog.subtitle" />
                </DialogContentText>
                <form className={classes.form}>
                  <Grid spacing={2} container justify="space-between">
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        name="firstname"
                        label={<Translate id="global.firstName" />}
                        fullWidth
                        type="text"
                        value={form.firstName.value}
                        onChange={(e) =>
                          form.firstName.setValue(e.currentTarget.value)
                        }
                        onKeyDown={handleKeyDown}
                        required
                        helperText={form.firstName.error || ""}
                        error={Boolean(form.firstName.error)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="lastname"
                        label={<Translate id="global.lastName" />}
                        fullWidth
                        type="text"
                        value={form.lastName.value}
                        onChange={(e) =>
                          form.lastName.setValue(e.currentTarget.value)
                        }
                        onKeyDown={handleKeyDown}
                        required
                        helperText={form.lastName.error || ""}
                        error={Boolean(form.lastName.error)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        name="email"
                        label={<Translate id="global.email" />}
                        value={form.email.value}
                        onChange={(e) =>
                          form.email.setValue(e.currentTarget.value)
                        }
                        type="email"
                        fullWidth
                        onKeyDown={handleKeyDown}
                        required
                        helperText={form.email.error || ""}
                        error={Boolean(form.email.error)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="password"
                        autoComplete="new-password"
                        label={<Translate id="global.password" />}
                        fullWidth
                        onChange={(e) =>
                          form.password.setValue(e.currentTarget.value)
                        }
                        type="password"
                        value={form.password.value}
                        helperText={form.password.error || <Translate id="SignUpDialog.passwordHelperText"/>}
                        required
                        error={Boolean(form.password.error)}
                        onKeyDown={handleKeyDown}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        name="confirm_password"
                        autoComplete="new-password"
                        label={<Translate id="global.confirmPassword" />}
                        fullWidth
                        onChange={(e) =>
                          form.confirmPassword.setValue(e.currentTarget.value)
                        }
                        type="password"
                        value={form.confirmPassword.value}
                        helperText={form.confirmPassword.error || ""}
                        required
                        error={Boolean(form.confirmPassword.error)}
                        onKeyDown={handleKeyDown}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="dense"
                        name="referral_code"
                        label={<Translate id="global.referralCode" />}
                        value={form.referralCode.value}
                        onChange={(e) =>
                          form.referralCode.setValue(e.currentTarget.value)
                        }
                        type="text"
                        fullWidth
                        helperText={
                          form.referralCode.error ||
                          (referredBy && (
                            <Translate
                              id="SignUpDialog.referralCodeHelper"
                              data={{ referredBy }}
                            />
                          )) || (
                            <Translate id="SignUpDialog.referralCodeHelper" />
                          )
                        }
                        error={Boolean(form.referralCode.error)}
                        onKeyDown={handleKeyDown}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Select
                        onNewValue={form.signupReason.setValue}
                        translate
                        margin="normal"
                        id="signup_reason"
                        variant="outlined"
                        fullWidth
                        label={translate("SignUpDialog.signupReasonLabel")}
                        error={!!form.signupReason.error}
                        helperText={form.signupReason.error}
                        options={signupReasons}
                        value={form.signupReason.value}
                      />
                      {showExplainSignupReason && (
                        <TextField
                          margin="dense"
                          name="signup_reason_other"
                          label={
                            <Translate id="SignUpDialog.signupReasonOtherLabel" />
                          }
                          value={form.signupReasonExtended.value}
                          onChange={(e) =>
                            form.signupReasonExtended.setValue(
                              e.currentTarget.value
                            )
                          }
                          type="text"
                          fullWidth
                          helperText={form.signupReasonExtended.error}
                          error={Boolean(form.signupReasonExtended.error)}
                          onKeyDown={handleKeyDown}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        style={{ marginRight: "3px" }}
                        label={<Translate id="SignUpDialog.iAgreeToThe" />}
                        control={
                          <Checkbox
                            value="acceptTermsOfService"
                            checked={form.agreesToTerms.value}
                            onChange={(e) =>
                              form.agreesToTerms.setValue(
                                e.currentTarget.checked
                              )
                            }
                            color="primary"
                          />
                        }
                      />
                      <Typography
                        variant="body1"
                        color="inherit"
                        display="inline"
                        style={{ verticalAlign: "middle" }}
                      >
                        <Link
                          href="https://s3.eu-west-2.amazonaws.com/app.bobcaat.com/attachments/legal/terms-of-service.pdf"
                          target="_blank"
                          noWrap
                          color="primary"
                        >
                          <Translate id="global.termsOfAgreement" />
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose} color="primary">
                  <Translate id="global.cancel" />
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={!form.agreesToTerms.value}
                  onClick={onSubmit}
                >
                  <Translate id="SignUpDialog.signUpNow" />
                </Button>
              </DialogActions>
            </div>
          </Grid>
          <Hidden xsDown>
            <Grid item xs={1} sm={5}>
              <div className={classes.splash}>
                <img src={logo} className={classes.logo} alt="Logo" />
                <Typography variant="h4" align="center">
                  bobcaat
                </Typography>
              </div>
            </Grid>
          </Hidden>
        </Grid>
      </Dialog>
    );
  }
);

signUpDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  referredBy: string,
  showExplainSignupReason: bool,
  form: object,
};

export default signUpDialog;
