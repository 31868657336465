export default {
  title: ["bobcaat - FAQ", "bobcaat - FAQ"],
  description: [
    "Read our FAQ and learn everything you need to know about bobcaat.",
    "Lis notre FAQ pour tout savoir sur bobcaat.",
  ],
  keywords: [
    "bobcaat, yellowstones, social media, social media platform, social media tool, faq, help, support, question",
    "bobcaat, yellowstones, réseaux sociaux, plateforme réseaux sociaux, outil réseaux sociaux, faq, aide, support, question",
  ],
};
