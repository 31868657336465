import FacebookIcon from "./FacebookIcon";
import LinkedinIcon from "./LinkedinIcon";
import InstagramIcon from "./InstagramIcon";
import PinterestIcon from "./PinterestIcon";
import TwitterIcon from "./TwitterIcon";
import GMBIcon from "./GMBIcon";
import TiktokIcon from "./TiktokIcon";

const channelIcons = {
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
  PinterestIcon,
  TwitterIcon,
  GMBIcon,
  TiktokIcon,
};
export default channelIcons;

export const colors = {
  facebook: "rgb(59,88,149)",
  linkedin: "rgb(13,100,149)",
  instagram: "rgb(187,0,104)",
  pinterest: "#E40023",
  gmb: "#77A6EF",
  twitter: "#2AA3EF",
  tiktok: "black",
};
