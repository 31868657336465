export default {
  wordOfMouth: ["Word of mouth", "Bouche à oreille"],
  instagram: ["Instagram", "Instagram"],
  linkedin: ["LinkedIn", "LinkedIn"],
  facebook: ["Facebook", "Facebook"],
  canva: ["Canva", "Canva"],
  otherSocialMedia: ["Other social media", "Autre réseau social"],
  searchEngine: ["Search engine", "Moteur de recherche"],
  other: ["Other (please explain)", "Autre (merci de préciser)"],
};
