export default {
  title: ["bobcaat - Compare plans", "bobcaat - Comparer les plans"],
  description: [
    "Compare and find the bobcaat plan that is right for you. Enjoy a 30-day free trial.",
    "Compare et trouve la formule bobcaat qui te convient. Profite d'un essai gratuit de 30 jours.",
  ],
  keywords: [
    "bobcaat, yellowstones, social media, social media platform, social media tool, plans,pricing, price, affordable, cheap, starter, partner, expert, master",
    "bobcaat, yellowstones, réseaux sociaux, plateforme réseaux sociaux, outil réseaux sociaux, formule, tarif, prix, starter, partner, expert, master",
  ],
};
