import React, { useContext } from "react";
import CTA from "./CTA";
import SignUpContext from "../../../contexts/signup";

const CTAContainer = () => {
  const { onOpenSignUp } = useContext(SignUpContext);

  return <CTA onSignUpNow={onOpenSignUp} />;
};

export default CTAContainer;
