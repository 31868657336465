export default {
  serverError: [
    "Unable to reach the server. Please retry later.",
    "Un problème est survenu. Merci de réessayer plus tard.",
  ],
  privacyPolicy: ["Privacy policy", "Politique de confidentialité"],
  termsOfAgreement: ["Terms of Agreement", "Conditions d'utilisation"],
  download: ["Download", "Télécharger"],
  reset: ["Reset", "Réinitialiser"],
  menu: ["Menu", "Menu"],
  open: ["Open", "Ouvrir"],
  created: ["Created", "Création"],
  unableToReachServer: [
    "Unable to reach the server.",
    "Impossible de joindre le serveur.",
  ],
  yourApprovalRequests: [
    "Your approval requests",
    "Tes demandes de validation",
  ],
  gif: ["GIF", "GIF"],
  username: ["Username", "Nom d'utilisateur"],
  account: ["Account", "Compte"],
  allOptions: ["All", "Tout"],
  attachment: ["Attachment", "Pièce jointe"],
  cancel: ["Cancel", "Annuler"],
  cancelEdit: ["Cancel edit", "Annuler"],
  category: ["Category", "Catégorie"],
  channel: ["Channel", "Chaîne"],
  close: ["Close", "Fermer"],
  colour: ["Colour", "Couleur"],
  confirm: ["Confirm", "Confirmer"],
  datePicker: ["Date", "Date"],
  daterange: ["Date range", "Période"],
  delete: ["Delete", "Supprimer"],
  description: ["Description", "Description"],
  duplicate: ["Duplicate", "Dupliquer"],
  edit: ["Edit", "Modifier"],
  email: ["Email", "Email"],
  export: ["Export", "Exporter"],
  filters: ["Filters", "Filtres"],
  firstName: ["First name", "Prénom"],
  fromdate: ["From", "De"],
  frozen: ["Frozen", "Suspendu"],
  hashtagGroup: ["Hashtag group", "Groupe de hashtags"],
  hashtags: ["Hashtags", "Hashtags"],
  go: ["Go", "C'est parti"],
  height: ["Height", "Hauteur"],
  help: ["Help", "Aide"],
  home: ["Home", "Accueil"],
  inviteAFriend: ["Invite a friend", "Invite un(e) ami(e)"],
  jobTitle: ["Job Title", "Activité"],
  justASec: ["Just a sec...", "Un instant..."],
  language: ["Language", "Langue"],
  lastName: ["Last name", "Nom"],
  loading: ["Loading...", "Chargement..."],
  logOut: ["Log out", "Se déconnecter"],
  login: ["Login", "Se connecter"],
  resetPassword: ["Forgot password", "Mot de passe oublié"],
  media: ["Media", "Média"],
  message: ["Message", "Message"],
  metamorph: ["Metamorph", "Métamorph"],
  monthly: ["Monthly", "Par mois"],
  name: ["Name", "Nom"],
  notifications: ["Notifications", "Notifications"],
  ok: ["OK", "OK"],
  password: ["Password", "Mot de passe"],
  referralCode: ["Referral code", "Code de parrainage"],
  confirmPassword: ["Confirm password", "Confirmer le mot de passe"],
  pickImage: ["Pick image", "Choisir une image"],
  place: ["Location", "Localisation"],
  plan: ["Plan", "Formule"],
  portfolio: ["Portfolio", "Portfolio"],
  post: ["Post", "Publication"],
  posts: ["Posts", "Publications"],
  profile: ["Profile", "Profil"],
  remove: ["Remove", "Supprimer"],
  reportABug: ["Report a bug", "Signaler un bug"],
  request: ["Request", "Récupérer"],
  currency: ["Currency", "Devise"],
  save: ["Save", "Enregistrer"],
  search: ["Search", "Rechercher"],
  searchPlaceholder: [
    "Type 3 letters to get started...",
    "Écrire 3 lettres pour commencer...",
  ],
  settings: ["Settings", "Paramètres"],
  sort: ["Sort", "Trier"],
  status: ["Status", "Statut"],
  story: ["Story", "Story"],
  supportTeam: ["Support team", "équipe support"],
  theme: ["Theme", "Thème"],
  timePicker: ["Time", "Horaire"],
  timezone: ["Time zone", "Fuseau horaire"],
  title: ["Title", "Titre"],
  todate: ["To", "À"],
  today: ["Today", "Aujourd'hui"],
  topic: ["Topic", "Sujet"],
  website: ["Website", "Site web"],
  websiteURL: ["Website URL", "URL site"],
  width: ["Width", "Largeur"],
  yourPosts: ["Your posts", "Tes publications"],
  copiedToClipboard: ["Copied to clipboard!", "Copié dans le presse-papiers !"],
  send: ["Send", "Envoyer"],
};
