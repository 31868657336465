import { Helmet } from "react-helmet";
import React from "react";
import { string } from "prop-types";

/**
 * Provides dynamic meta headers for a better SEO.
 * @param url {string} The page URL.
 * @param keywords {string} coma separated page keywords.
 * @param title {string} Page title.
 * @param description {string} Page description.
 * @return {JSX.Element}
 */
const helmet = ({ url, keywords, title, description }) => (
  <Helmet>
    {url && <meta property="og:url" content={url} />}
    {keywords && <meta name="keywords" content={keywords} />}
    {description && <meta name="description" content={description} />}
    {title && <meta property="og:title" content={title} />}
    {description && <meta property="og:description" content={description} />}
    {title && <title>{title}</title>}
  </Helmet>
);

helmet.propTypes = {
  url: string,
  keywords: string,
  title: string,
  description: string,
};

export default helmet;
