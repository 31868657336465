import ajax from "../lib/ajax";

const REDIRECT_TO_APP_BASE = `${process.env.REACT_APP_APP_URL}?t=`;

export const signUp = ({
  email,
  firstName,
  lastName,
  referralCode,
  invitationCode,
  password,
  signupReason,
}) =>
  ajax.post(`/auth/signup`, {
    email,
    first_name: firstName,
    last_name: lastName,
    password,
    referral_code: referralCode,
    invitation_code: invitationCode,
    signup_reason: signupReason,
  });

export const signIn = ({ email, password }) =>
  ajax
    .post("/auth/signin", { email, password })
    .then(
      (r) => (window.location.href = REDIRECT_TO_APP_BASE + r.data.data.token)
    );

export const requestPasswordReset = ({ username }) =>
  ajax.get(`/auth/reset?email=${username}`);
