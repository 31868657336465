import locales from "./locales";
import strings from "./strings";
import { renderToStaticMarkup } from "react-dom/server";

/**
 * Translates a string into specified locale.
 * @param {Array} localizedStrings The array containing the different versions of the string.
 * @param {object} locale The Refer object representing the locale.
 * @param {object} data Optional object containing format parameters.
 * @return {string} The final translated string
 */
export const translate = (localizedStrings, locale, data = undefined) => {
  let string = localizedStrings[locale.idx];
  if (data) {
    Object.keys(data).forEach((key) => {
      string = string.replace(`$\{${key}}`, data[key]);
    });
  }
  return string;
};

export const initLocalization = (initialize) => {
  initialize({
    languages: locales.map(({ name, slug }) => ({ name, code: slug })),
    translation: strings,
    options: {
      renderToStaticMarkup,
      onMissingTranslation: ({ translationId }) => translationId,
    },
  });
};
