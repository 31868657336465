import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { isEmpty } from "lodash";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Section from "../../lib/hoc/Section";
import { Translate } from "react-localize-redux";
import { bool, object, func } from "prop-types";
import Select from "../../lib/Select";
import supportCategories from "../../../refer/supportCategories";
import WithInputLimits from "../../lib/hoc/WithInputLimits";
import useTranslate from "../../../hooks/useTranslate";
import {BugReport} from "@material-ui/icons";

const styles = (theme) => ({
  root: {
    paddingTop: theme.metrics.appBarHeight + theme.spacing(6),
  },
  paper: {
    padding: theme.spacing(2),
  },
});

const contact = withStyles(styles)(
  ({ onSubmit, contactForm, isAuthenticatedUser, classes }) => {
    const translate = useTranslate();

    return (
      <Section className={classes.root}>
        <Grid container justify="space-between">
          <Grid item sm={12} md={5}>
            <div>
              <Typography variant="h3" component="h1" gutterBottom>
                <Translate id="Contact.title" />
              </Typography>
              <Typography paragraph>
                <Translate
                  id="Contact.subtitle"
                  options={{ renderInnerHtml: true }}
                />
              </Typography>
                <Alert severity="warning" icon={<BugReport />}>
                  <AlertTitle>
                    {translate("Contact.bugAlertTitle")}
                  </AlertTitle>
                  {translate("Contact.bugAlertMessage")}
                </Alert>
            </div>
          </Grid>
          <Grid item sm={12} md={5}>
            <Paper elevation={10} className={classes.paper}>
              <Select
                onNewValue={contactForm.category.setValue}
                translate
                margin="normal"
                id="support-category"
                variant="outlined"
                fullWidth
                label={translate("global.topic")}
                error={!!contactForm.category.error}
                helperText={contactForm.category.error}
                options={supportCategories}
                value={contactForm.category.value}
              />
              {!isAuthenticatedUser && (
                <>
                  <TextField
                    label={<Translate id="global.firstName" />}
                    fullWidth
                    margin="normal"
                    required
                    value={contactForm.firstName.value}
                    error={!!contactForm.firstName.error}
                    helperText={contactForm.firstName.error}
                    onChange={(e) =>
                      contactForm.firstName.setValue(e.currentTarget.value)
                    }
                  />
                  <TextField
                    label={<Translate id="global.lastName" />}
                    fullWidth
                    margin="normal"
                    required
                    value={contactForm.lastName.value}
                    error={!!contactForm.lastName.error}
                    helperText={contactForm.lastName.error}
                    onChange={(e) =>
                      contactForm.lastName.setValue(e.currentTarget.value)
                    }
                  />
                  <TextField
                    label={<Translate id="global.email" />}
                    fullWidth
                    margin="normal"
                    required
                    value={contactForm.email.value}
                    error={!!contactForm.email.error}
                    helperText={contactForm.email.error}
                    onChange={(e) =>
                      contactForm.email.setValue(e.currentTarget.value)
                    }
                  />
                </>
              )}
              <WithInputLimits
                maxLength={100}
                onChange={(e) =>
                  contactForm.title.setValue(e.currentTarget.value)
                }
                value={contactForm.title.value}
                error={!!contactForm.title.error}
                helperText={contactForm.title.error}
              >
                <TextField
                  disabled={isEmpty(contactForm.category.value)}
                  label={<Translate id="global.title" />}
                  placeholder={translate("Contact.titleHelp")}
                  fullWidth
                  margin="normal"
                  required
                />
              </WithInputLimits>
              <WithInputLimits
                maxLength={2048}
                onChange={(e) =>
                  contactForm.description.setValue(e.currentTarget.value)
                }
                value={contactForm.description.value}
                error={!!contactForm.description.error}
                helperText={contactForm.description.error}
              >
                <TextField
                  disabled={isEmpty(contactForm.category.value)}
                  multiline
                  rows={8}
                  label={<Translate id="global.description" />}
                  placeholder={translate("Contact.descriptionHelp")}
                  fullWidth
                  margin="normal"
                  required
                />
              </WithInputLimits>
              <Typography align="right">
                <Button
                  variant="contained"
                  disabled={isEmpty(contactForm.category.value)}
                  color="primary"
                  onClick={onSubmit}
                >
                  <Translate id="global.send" />
                </Button>
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Section>
    );
  }
);

contact.propTypes = {
  isAuthenticatedUser: bool,
  contactForm: object,
  onSubmit: func,
};

export default contact;
