import React, { useContext, useEffect, useState } from "react";
import * as auth from "../../api/auth";
import {
  checkEmail,
  checkSignUpConfirmPassword,
  checkSignUpFirstName,
  checkSignUpLastName,
  checkSignUpPassword,
  checkSignupReason,
  checkSignupReasonExtended,
  checkSignUpReferralCode,
  validateField,
} from "../../lib/validation";
import SignUpDialog from "./SignUpDialog";
import { every } from "lodash";
import SignUpContext from "../../contexts/signup";
import SnackContext from "../../contexts/snacks";
import useForm from "../../hooks/useForm";
import { Translate } from "react-localize-redux";

const SignUpDialogContainer = () => {
  const { onCloseSignUp, open } = useContext(SignUpContext);
  const { onSetSuccess } = useContext(SnackContext);
  const [referredBy, setReferredBy] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [showExplainSignupReason, setShowExplainSignupReason] = useState(false);

  const onNewFormValue = (fieldName, val) => {
    if (fieldName === "signupReason") {
      setShowExplainSignupReason(val === "other");
    }
  };

  const form = useForm(
    {
      email: "",
      password: "",
      confirmPassword: "",
      lastName: "",
      firstName: "",
      referralCode: "",
      signupReason: "",
      signupReasonExtended: "",
      agreesToTerms: false,
    },
    onNewFormValue
  );

  useEffect(() => {
    const invite = window.sessionStorage.getItem("invite");
    if (invite) {
      const inviteDetails = JSON.parse(invite);
      setReferredBy(inviteDetails.referred_by);
      setInvitationCode(inviteDetails.code);
      form.referralCode.setValue(inviteDetails.referral_code);
      form.email.setValue(inviteDetails.email);
      form.firstName.setValue(inviteDetails.name.split(" ")[0]);
      form.lastName.setValue(inviteDetails.name.split(" ").slice(-1)[0]);
    }
  }, [open, form]);

  const handleClose = () => {
    setReferredBy("");
    setInvitationCode("");
    form.clear();
    onCloseSignUp();
  };

  const handleSubmit = () => {
    const isValid = every(
      [
        validateField(checkEmail, form.email),
        validateField(checkSignupReason, form.signupReason),
        validateField(checkSignUpPassword, form.password),
        validateField(checkSignUpConfirmPassword, form.confirmPassword),
        validateField(checkSignUpFirstName, form.firstName),
        validateField(checkSignUpLastName, form.lastName),
        validateField(checkSignUpReferralCode, form.referralCode),
        !showExplainSignupReason ||
          validateField(checkSignupReasonExtended, form.signupReasonExtended),
      ],
      Boolean
    );
    const passwordsMatch = form.password !== form.confirmPassword;
    if (!passwordsMatch) {
      form.confirmPassword.setError(
        <Translate id="SignUpDialog.passwordsDoNotMatch" />
      );
    }

    if (isValid && passwordsMatch) {
      auth
        .signUp({
          ...form.collect(),
          invitationCode,
          signupReason: showExplainSignupReason
            ? form.signupReasonExtended.value
            : form.signupReason.value,
        })
        .then((r) => {
          window.sessionStorage.removeItem("invite");
          onSetSuccess(r.data.data.message);
          handleClose();
        });
    }
  };

  return (
    <SignUpDialog
      open={open}
      referredBy={referredBy}
      form={form}
      showExplainSignupReason={showExplainSignupReason}
      onClose={handleClose}
      onSubmit={handleSubmit}
    />
  );
};

export default SignUpDialogContainer;
