import React from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import {
  any,
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOf,
  oneOfType,
  string,
} from "prop-types";
import InputAdornment from "@material-ui/core/InputAdornment";
import selectOption from "../../shapes/selectOption";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FilledInput from "@material-ui/core/FilledInput";
import Input from "@material-ui/core/Input";
import { compact, identity, noop, reduce } from "lodash";
import useTranslate from "../../hooks/useTranslate";
import { useWidthDown } from "../../util/breakpoints";

const styles = () => ({
  root: { minWidth: "125px" },
});

const getMaxWidthFromOptions = (options) =>
  reduce(options, (p, { label }) => Math.max(label.length * 8, p), 0) + 55;

const select = withStyles(styles)(
  ({
    id,
    label,
    helperText,
    value,
    onChange,
    fullWidth,
    variant,
    autoInputWidth,
    translate,
    children,
    Icon,
    margin,
    options,
    multiple,
    onNewValue,
    classes,
    emptyOption,
    style,
    error,
    ...props
  }) => {
    const translator = useTranslate();
    const finalOptions =
      typeof emptyOption === "undefined"
        ? options
        : [{ value: "", label: emptyOption }].concat(options);

    const isNative = !multiple && useWidthDown("xs");
    const getLabel = translate ? translator : identity;
    const makeOption = isNative
      ? ({ value, label }) => (
          <option key={value} value={value}>
            {getLabel(label)}
          </option>
        )
      : ({ value, label }) => (
          <MenuItem key={value} value={value}>
            {getLabel(label)}
          </MenuItem>
        );

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);

    React.useEffect(() => {
      if (inputLabel.current) {
        setLabelWidth(inputLabel.current.offsetWidth);
      }
    }, [label]);

    const width = autoInputWidth
      ? getMaxWidthFromOptions(finalOptions) + "px"
      : "auto";
    const inputProps = { name: id, id };

    const input =
      variant === "outlined" ? (
        <OutlinedInput
          notched={isNative || undefined}
          {...inputProps}
          labelWidth={labelWidth}
        />
      ) : variant === "filled" ? (
        <FilledInput {...inputProps} />
      ) : (
        <Input {...inputProps} />
      );

    const handleChange = (e) => {
      onChange(e);
      onNewValue(
        isNative && multiple
          ? compact(
              [...e.target.options].map((op) =>
                op.selected ? op.value : undefined
              )
            )
          : e.target.value
      );
    };

    return (
      <FormControl
        fullWidth={fullWidth}
        margin={margin}
        variant={variant}
        style={style}
        error={error}
      >
        {label && (
          <InputLabel
            shrink={!!Icon || isNative || undefined}
            ref={inputLabel}
            htmlFor={id}
          >
            {label}
          </InputLabel>
        )}
        <Select
          {...props}
          style={{ width }}
          classes={{ root: classes.root }}
          value={value || (multiple ? [] : "")}
          onChange={handleChange}
          native={isNative}
          displayEmpty={!!Icon}
          input={input}
          multiple={multiple}
          startAdornment={
            Icon ? (
              <InputAdornment position="start">
                <Icon htmlColor="#AAA" />
              </InputAdornment>
            ) : null
          }
        >
          {finalOptions.map(makeOption)}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

select.propTypes = {
  id: string,
  label: oneOfType([string, node]),
  helperText: node,
  value: oneOfType([string, bool, number, object, array]),
  onChange: func, // Native event.
  onNewValue: func.isRequired, // Consistent accross native/ not native.
  fullWidth: bool,
  variant: oneOf(["standard", "outlined", "filled"]),
  Icon: any,
  margin: oneOf(["none", "dense", "normal"]),
  options: arrayOf(selectOption),
  autoInputWidth: bool,
  multiple: bool,
  emptyOption: node,
  error: bool,
  translate: bool,
};

select.defaultProps = {
  fullWidth: false,
  variant: "standard",
  margin: "none",
  autoInputWidth: false,
  multiple: false,
  onChange: noop,
  onNewValue: noop,
  error: false,
};

export default select;
