import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import classnames from "classnames";
import { arrayOf, bool, func, node, object, shape, string } from "prop-types";
import { lighten } from "@material-ui/core/styles";
import { useWidthDown } from "../../../../util/breakpoints";

const styles = (theme) => ({
  paper: {
    overflowX: "auto",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  table: {},
  tableHead: {
    [theme.breakpoints.up("sm")]: {
      fontSize: "1rem",
    },
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.background.default,
    },
  },
  mainTableHead: {
    backgroundColor: theme.palette.primary.main,
    border: "solid 2px " + theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  tableCell: {
    whiteSpace: "pre-line",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0.5),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
  mainTableCell: {
    borderLeft: "solid 2px " + theme.palette.primary.main,
    borderRight: "solid 2px " + theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.6),
  },
  thumb: {
    display: "block",
    margin: "auto",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxHeight: 64,
    [theme.breakpoints.down("xs")]: {
      maxHeight: 48,
    },
  },
});

const table = withStyles(styles)(
  ({ columns, rows, renderer, noHeader, classes }) => {
    const isMobile = useWidthDown("xs");
    return (
      <Paper className={classes.paper} elevation={0}>
        <Table className={classes.table}>
          {!noHeader && (
            <TableHead>
              <TableRow>
                {columns.map((col) => (
                  <TableCell
                    style={col.width ? { width: col.width } : undefined}
                    key={col.id}
                    align="center"
                    padding="none"
                    className={classnames(
                      classes.tableHead,
                      col.isMain && classes.mainTableHead
                    )}
                  >
                    {col.label}
                    <img
                      src={col.thumb}
                      className={classes.thumb}
                      alt={col.label}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          )}
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} className={classes.row}>
                {columns.map((col, index) => (
                  <TableCell
                    style={col.width ? { width: col.width } : undefined}
                    className={classnames(
                      classes.tableCell,
                      col.isMain && classes.mainTableCell
                    )}
                    component={index > 0 ? "td" : "th"}
                    key={col.id}
                    padding={index > 0 || isMobile ? "none" : "default"}
                    scope="row"
                    align={index > 0 ? "center" : "left"}
                  >
                    {renderer(row, col)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    );
  }
);

table.propTypes = {
  noHeader: bool,
  rows: arrayOf(object).isRequired,
  columns: arrayOf(
    shape({
      id: string.isRequired,
      label: node.isRequired,
      width: string,
      isMain: bool.isRequired,
    })
  ).isRequired,
  renderer: func,
};

table.defaultProps = {
  noHeader: false,
  renderer: (row, col) => row[col.id],
};

export default table;
