const legalDocuments = [
  {
    label: "global.privacyPolicy",
    target: "_blank",
    href: `${process.env.REACT_APP_ATTACHMENTS_URL}/legal/privacy-policy.pdf`,
  },
  {
    label: "global.termsOfAgreement",
    target: "_blank",
    href: `${process.env.REACT_APP_ATTACHMENTS_URL}/legal/terms-of-service.pdf`,
  },
];
export default legalDocuments;
