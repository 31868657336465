import { flow, noop } from "lodash";

/**
 * Returns a function that prepends specified string to a value.
 * @param s {string}
 * @return {function(*): string}
 */
export const prepend = (s) => (v) => s + v.toString();
/**
 * Returns a function that appends specified string to a value.
 * @param s {string}
 * @return {function(*): string}
 */
export const append = (s) => (v) => v.toString() + s;

/**
 * Call `func` with `value`, only if `value !== undefined`.
 * @param func {function}
 * @return {function(*=): void}
 */
export const applyIfDefined = (func) => (value) => {
  if (value !== undefined) func(value);
};

/**
 * Call `func` with `value`, only if `Boolean(value)`.
 * @param func {function}
 * @return {function(*=): void}
 */
export const applyIfTruthy = (func) => (value) => {
  if (Boolean(value)) func(value);
};

/**
 * Decorates a function to call it only if its sole argument is different than last
 * time it was called.
 * @param initialValue
 * @return {function(*): function(*): *}
 */
export const withSwitchGuard = (initialValue) => (func) => {
  let last = initialValue;
  return (param) => (last !== param ? func((last = param)) : undefined);
};

/**
 * A function that takes anything and returns nothing.
 * @return {undefined}
 */

/**
 * A flow that does not return anything.
 * @param funcs {function}
 * @return {function(...args: any[]): *}
 */
export const sinkFlow = (...funcs) => flow(...funcs, noop);
