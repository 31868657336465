import React from "react";
import About from "./About";
import Footer from "../Footer";
import Helmet from "../lib/Helmet";
import useTranslate from "../../hooks/useTranslate";
import Page from "../lib/hoc/Page";

const AboutPage = () => {
  const translate = useTranslate();
  return (
    <Page>
      <Helmet
        title={translate("AboutPage.title")}
        description={translate("AboutPage.description")}
        keywords={translate("AboutPage.keywords")}
        url={process.env.REACT_APP_LANDING_URL + "/about"}
      />
      <About />
      <Footer />
    </Page>
  );
};
export default AboutPage;
