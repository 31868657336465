import React, { useContext } from "react";
import NewsletterSignUp from "./NewsletterSignUp";
import useForm from "../../../hooks/useForm";
import { every } from "lodash";
import { checkEmail, validateField } from "../../../lib/validation";
import * as misc from "../../../api/misc";
import SnackContext from "../../../contexts/snacks";

const NewsletterSignUpContainer = () => {
  const { onSetSuccess } = useContext(SnackContext);

  const form = useForm({
    email: "",
  });

  const handleSubmit = () => {
    const isValid = every([validateField(checkEmail, form.email)], Boolean);
    if (isValid) {
      misc.marketingSignUp(form.collect()).then((r) => {
        console.log(r);
        window.localStorage["hasInterest"] = true;
        onSetSuccess(r.data.message);
      });
    }
  };

  return <NewsletterSignUp form={form} onSubmit={handleSubmit} />;
};

export default NewsletterSignUpContainer;
