import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const pinterestIcon = React.forwardRef(({ htmlColor, ...props }, ref) => (
  <SvgIcon
    ref={ref}
    titleAccess="Pinterest"
    {...props}
    htmlColor={htmlColor || "inherit"}
    focusable="false"
    aria-hidden="true"
    data-icon="pinterest"
    viewBox="0 0 508.52 508.52"
  >
    <path
      d="M254.26,0C113.845,0,0,113.845,0,254.26s113.845,254.26,254.26,254.26
				s254.26-113.845,254.26-254.26C508.52,113.813,394.675,0,254.26,0z M274.092,323.005c-18.593-1.43-26.379-10.647-40.936-19.514
				c-8.009,42.016-17.798,82.317-46.784,103.325c-8.963-63.47,13.126-111.143,23.392-161.773
				c-17.48-29.462,2.098-88.673,38.965-74.085c45.354,17.957-39.315,109.427,17.544,120.869
				c59.338,11.918,83.588-103.007,46.784-140.383c-53.204-53.967-154.781-1.24-142.29,76.024c3.051,18.879,22.534,24.6,7.787,50.693
				c-34.039-7.501-44.209-34.389-42.906-70.176c2.098-58.575,52.632-99.575,103.325-105.264
				c64.105-7.183,124.238,23.519,132.533,83.81C380.881,254.578,342.583,328.313,274.092,323.005z"
    />
  </SvgIcon>
));

export default pinterestIcon;
