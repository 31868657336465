import { useState } from "react";
import useMountEffect from "./useMountEffect";

const useScrollTrigger = ({ threshold }) => {
  const [beyondThreshold, setbeyondThreshold] = useState(
    Boolean(window.scrollY > threshold)
  );
  useMountEffect(() => {
    // onScroll listens to the window scroll, but only changes
    // the state if the difference is beyonf the threshold.
    // We have to keep a local cache of the value, since only initial
    // beyondThreshold will be caught in the closure.
    let lastBeyond = beyondThreshold;
    const onScroll = () => {
      const isBeyond = window.scrollY > threshold;
      if (isBeyond !== lastBeyond) {
        setbeyondThreshold((lastBeyond = isBeyond));
      }
    };
    window.addEventListener("scroll", onScroll);
    // Don't forget to return an unmount handler to clear the listener.
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });
  return beyondThreshold;
};
/**
 * Listens to window scroll and fires when scrolls goes either side of a threshold.
 * @param options {{threshold: number}} Options to parameterize the listener.
 * @return {boolean} Whether the current scroll is below (false) or above (true) the threshold.
 */
export default useScrollTrigger;
