import React, { useState } from "react";
import { wrap } from "lodash";
import { sinkFlow } from "../lib/func";
import useMountEffect from "../hooks/useMountEffect";
import { initLocalization } from "../localization";
import useUpdateLanguage from "../hooks/useUpdateLanguage";
import { LocalizeProvider, withLocalize } from "react-localize-redux";

const InnerProvider = withLocalize(({ initialize, children }) => {
  const [ready, setReady] = useState(false);

  // Initialize localization.
  useMountEffect(
    sinkFlow(
      wrap(initialize, initLocalization),
      useUpdateLanguage(),
      wrap(true, setReady)
    )
  );

  return ready ? children : <React.Fragment />;
});

/**
 * Used only once to wrap the whole app with a Localization context.
 *
 * In this case we do not provide the context ourselves but we initialise it.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const LocalizationProvider = ({ children }) => (
  <LocalizeProvider>
    <InnerProvider>{children}</InnerProvider>
  </LocalizeProvider>
);
