export default {
  title: ["Sign into bobcaat", "Se connecter à bobcaat"],
  subtitle: [
    "Please enter your email address and your password and you will be redirected to the application.",
    "Entre ton adresse email et ton mot de passe et tu seras redirigé vers l'application.",
  ],
  resetPasswordRequestSent: [
    "Sent! Allow a few minutes before checking your inbox.",
    "Envoyé ! Patiente quelques instants puis vérifie tes emails.",
  ],
};
