import React from "react";
import useTranslate from "../../hooks/useTranslate";
import Page from "../lib/hoc/Page";
import Helmet from "../lib/Helmet";
import Pricing from "./Pricing";
import Footer from "../Footer";

const PricingPage = () => {
  const translate = useTranslate();
  return (
    <Page>
      <Helmet
        title={translate("PricingPage.title")}
        description={translate("PricingPage.description")}
        keywords={translate("PricingPage.keywords")}
        url={process.env.REACT_APP_LANDING_URL + "/plans"}
      />
      <Pricing />
      <Footer />
    </Page>
  );
};

export default PricingPage;
