// Opera 8.0+
const isOpera =
  (!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(" OPR/") >= 0;

// Firefox 1.0+
const isFirefox = typeof InstallTrigger !== "undefined";

const isSafari = /safari/.test(navigator.userAgent.toLowerCase());

// Internet Explorer 6-11
const isIE = /*@cc_on!@*/ !!document.documentMode;

// Edge 20+
const isEdge = !isIE && !!window.StyleMedia;

// Chrome 1 - 71
const isChrome =
  !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

const isIOSBase =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.maxTouchPoints &&
    navigator.maxTouchPoints > 2 &&
    /MacIntel/.test(navigator.platform));

const isIOS = isIOSBase && !isSafari; // Webview

const isMobileSafari = isIOSBase && isSafari;

const isAndroid = typeof window.Android !== "undefined";

const isDevice = isIOS || isAndroid;

export {
  isOpera,
  isFirefox,
  isSafari,
  isIE,
  isEdge,
  isChrome,
  isIOS,
  isAndroid,
  isDevice,
  isMobileSafari,
};
