import React from "react";

const Wave = ({ style, fill, ...props }) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 2353.6 1112.5"
    fill={fill || "white"}
    style={{ enableBackground: "new 0 0 2353.6 1112.5", ...style }}
    {...props}
  >
    <path
      className="st0"
      d="M2353.6,0v1112.5H0c132.1-171.4,350.7-231.4,655.9-179.9c474,79.9,637.1-23.3,882.7-262.4C1802.7,413,1870,54.1,2353.6,0z"
    />
  </svg>
);
export default Wave;
