import React from "react";
import Helmet from "../lib/Helmet";
import useTranslate from "../../hooks/useTranslate";
import Jumbo from "./Jumbo";
import Footer from "../Footer";
import SocialHighlights from "./SocialHighlights";
import CTA from "./CTA";
import Guarantees from "./Guarantees";
import NewsletterSignUp from "./NewsletterSignUp";
import VideoDemo from "./VideoDemo";
import Features from "./Features";
import Benefits from "./Benefits";
import Page from "../lib/hoc/Page";

const LandingPage = () => {
  const translate = useTranslate();
  return (
    <Page>
      <Helmet
        title={translate("LandingPage.title")}
        description={translate("LandingPage.description")}
        keywords={translate("LandingPage.keywords")}
        url={process.env.REACT_APP_LANDING_URL}
      />
      <Jumbo />
      <SocialHighlights />
      <Guarantees />
      <Features />
      <VideoDemo />
      <Benefits />
      <CTA />
      <NewsletterSignUp />
      <Footer />
    </Page>
  );
};

export default LandingPage;
