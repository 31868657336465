import React, { useContext } from "react";
import CurrencyContext from "../../contexts/currency";
import Footer from "./Footer";
import useUpdateLanguage from "../../hooks/useUpdateLanguage";
import useRequestContext from "../../hooks/useRequestContext";

const FooterContainer = () => {
  const { isAuthenticatedUser } = useRequestContext();
  const { onSetCurrency } = useContext(CurrencyContext);
  const onSetLocale = useUpdateLanguage();

  if (isAuthenticatedUser) {
    // Coming from the app. Do not show any link.
    return <div />;
  }
  return <Footer onSetCurrency={onSetCurrency} onSetLocale={onSetLocale} />;
};

export default FooterContainer;
