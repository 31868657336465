import * as React from "react";
import { any, bool, element, func, node, number } from "prop-types";
import { noop } from "lodash";

const withInputLimits = ({
  maxLength,
  onChange,
  value,
  forbidWrongs,
  error,
  helperText,
  children,
  ...leftProps
}) => {
  const changeHandler = (e) => {
    if (!forbidWrongs || e.target.value.length <= maxLength) {
      onChange(e);
    }
  };

  return React.cloneElement(children, {
    onChange: (e) => {
      changeHandler(e);
    },
    value: value,
    error: error || value.length > maxLength,
    helperText: helperText || `${value.length} / ${maxLength}`,
    ...leftProps,
  });
};

withInputLimits.propTypes = {
  children: element.isRequired,
  maxLength: number.isRequired,
  helperText: node,
  onChange: func,
  value: any,
  error: bool,
  forbidWrongs: bool,
};

withInputLimits.defaultProps = {
  helperText: "",
  onChange: noop,
  value: "",
  error: false,
  forbidWrongs: false,
};

export default withInputLimits;
