import { LocalizeContext } from "react-localize-redux";
import { useContext, useState } from "react";
import { en_gb, getLocale } from "../localization/locales";

const useLocale = () => {
  const { activeLanguage } = useContext(LocalizeContext);
  const [cachedLocale, setCachedLocale] = useState(null);

  // We cache our version of the locale to avoid searching it each time.
  // But we need to update it when the actual locale changes.
  if (!activeLanguage) {
    return en_gb;
  }
  if (!cachedLocale || cachedLocale.slug !== activeLanguage.code) {
    const locale = getLocale(activeLanguage.code);
    setCachedLocale(locale);
    return locale;
  }
  return cachedLocale;
};
export default useLocale;
