import { createMuiTheme } from "@material-ui/core/styles";
import { grey, orange, red } from "@material-ui/core/colors";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";

const opacities = [...Array(101).keys()].map((n) => n / 100);

/**
 * The theme of the application.
 * It should contain all semantic colors and typography definitions for the
 * app.
 */
const lightThemeOptions = {
  // Colors
  palette: {
    primary: { main: "#C5BB9A" },
    secondary: { main: "#F7E7CE", contrastText: grey[700] },
    ternary: { main: "#FFECE5" },
    error: red,
    warning: { main: orange[500], light: orange[100], dark: orange[800] },
  },

  // Typography
  typography: { fontFamily: '"Raleway", sans-serif' },

  // Custom
  shades: {
    darker: opacities.map((o) => `rgba(0,0,0,${o})`),
    lighter: opacities.map((o) => `rgba(255,255,255,${o})`),
  },

  overrides: {
    MuiTypography: {
      root: {
        fontFamily: '"Raleway", sans-serif',
      },
      subtitle1: {
        fontWeight: "bold",
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",
      },
      root: {
        textTransform: "none",
        fontWeight: "bold",
      },
    },
  },

  animations: {
    beat: "beat ease infinite",
    bounce: "bounce ease",
    floatY: "floatY ease-in-out infinite",
    spinCircle: "spin linear infinite",
    hueRotate: "hue-rotate linear infinite",
    grayscale: "grayscale ease alternate infinite",
    pulseWhite: "pulse-white ease infinite",
    pulsePrimary: "pulse-primary ease infinite",
    pulseSecondary: "pulse-secondary ease infinite",
  },

  // Constant
  metrics: {
    appBarHeight: 96,
  },
};

export const light = responsiveFontSizes(createMuiTheme(lightThemeOptions));
