import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo from "../../assets/images/bobcaat.png";
import logoWhite from "../../assets/images/bobcaat_white.png";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { wrap } from "lodash";
import { Hidden, Typography, withStyles } from "@material-ui/core";
import locales, { getFlag } from "../../localization/locales";
import { Translate } from "react-localize-redux";
import usePopupMenu from "../../hooks/usePopupMenu";
import { bool, func } from "prop-types";
import { sinkFlow } from "../../lib/func";
import shapes from "../../shapes";
import Divider from "@material-ui/core/Divider";
import routes from "../../routes";
import useScrollTrigger from "../../hooks/useScrollTrigger";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    transition: "background-color 0.2s ease, box-shadow 0.2s ease",
  },
  toolbar: {
    flexGrow: 1,
    margin: "auto",
    padding: theme.spacing(2),
    transition: "width 0.5s ease",
    [theme.breakpoints.up("xs")]: { width: "100%" },
    [theme.breakpoints.up("sm")]: { width: "98%" },
    [theme.breakpoints.up("md")]: { width: "92%" },
    [theme.breakpoints.up("lg")]: { width: "85%" },
  },
  logoWrapper: {
    display: "flex",
    alignItems: "center",
  },
  logo: {
    width: "60px",
    marginRight: theme.spacing(2),
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    cursor: "pointer",
    textDecoration: "none",
    color: theme.palette.text.secondary,
    whiteSpace: "nowrap",
    marginRight: theme.spacing(2),
    "&:hover": {
      textDecoration: "none",
    },
  },
  button: {
    marginLeft: theme.spacing(2),
  },
});

const DesktopSection = ({ classes, signButtons, onSetLocale, locale }) => {
  const [LanguageMenu, onOpenLanguageMenu] = usePopupMenu();

  return (
    <Hidden smDown>
      {routes
        .filter((route) => !route.hidden)
        .map(({ label, href }) => (
          <Button
            component={Link}
            to={href}
            key={href}
            className={classes.link}
          >
            <Translate id={label} />
          </Button>
        ))}
      <Button
        component="a"
        className={classes.link}
        onClick={onOpenLanguageMenu}
      >
        {getFlag(locale)}
      </Button>
      <LanguageMenu>
        {locales.map(({ slug, name }) => (
          <MenuItem component="a" onClick={wrap(slug, onSetLocale)} key={slug}>
            {name}
          </MenuItem>
        ))}
      </LanguageMenu>
      <Divider orientation="vertical" flexItem />
      {signButtons.map(({ label, slug, click, standout }) => (
        <Button
          variant={standout ? "contained" : "text"}
          color={standout ? "primary" : "inherit"}
          className={classes.button}
          onClick={click}
          key={slug}
        >
          {label}
        </Button>
      ))}
    </Hidden>
  );
};

const MobileSection = ({ classes, onSetLocale, signButtons }) => {
  const [MobileMenu, onOpenMobileMenu, onCloseMobileMenu] = usePopupMenu();

  return (
    <Hidden mdUp>
      <Button
        variant="outlined"
        color="inherit"
        className={classes.button}
        onClick={onOpenMobileMenu}
      >
        <Translate id="global.menu" />
      </Button>
      <MobileMenu>
        {routes
          .filter((route) => !route.hidden)
          .map(({ label, href }, index) => (
            <MenuItem
              component={Link}
              divider={index === routes.length - 1}
              onClick={onCloseMobileMenu}
              to={href}
              key={href}
            >
              <Translate id={label} />
            </MenuItem>
          ))}
        {locales.map(({ slug, name }, index) => (
          <MenuItem
            component="a"
            onClick={() => onSetLocale(slug)}
            divider={index === locales.length - 1}
            key={slug}
          >
            {name}
          </MenuItem>
        ))}
        {signButtons.map(({ label, slug, click }) => (
          <MenuItem onClick={sinkFlow(click, onCloseMobileMenu)} key={slug}>
            {label}
          </MenuItem>
        ))}
      </MobileMenu>
    </Hidden>
  );
};

const appBar = withStyles(styles)(
  ({
    classes,
    onOpenSignInDialog,
    onOpenSignUpDialog,
    restrictedView,
    locale,
    onSetLocale,
    forceColoredLogo,
  }) => {
    const signButtons = [
      {
        label: <Translate id="AppBar.signIn" />,
        click: onOpenSignInDialog,
        slug: "signin",
        standout: false,
      },
      {
        label: <Translate id="AppBar.signUp" />,
        click: onOpenSignUpDialog,
        slug: "signup",
        standout: true,
      },
    ];

    const showElevated = useScrollTrigger({ threshold: 30 });
    return (
      <AppBar
        position="fixed"
        color={showElevated ? "inherit" : "transparent"}
        className={classes.root}
        elevation={showElevated ? 3 : 0}
      >
        <Toolbar className={classes.toolbar}>
          <Link className={classes.link} to="/">
            <div className={classes.logoWrapper}>
              <img
                src={showElevated || forceColoredLogo ? logo : logoWhite}
                className={classes.logo}
                alt="Logo"
              />
              <Typography display="inline" variant="h5">
                bobcaat
              </Typography>
            </div>
          </Link>
          <div className={classes.grow} />
          {!restrictedView && (
            <>
              <DesktopSection
                classes={classes}
                locale={locale}
                onSetLocale={onSetLocale}
                signButtons={signButtons}
              />
              <MobileSection
                classes={classes}
                onSetLocale={onSetLocale}
                signButtons={signButtons}
              />
            </>
          )}
        </Toolbar>
      </AppBar>
    );
  }
);

appBar.propTypes = {
  onOpenSignInDialog: func.isRequired,
  onOpenSignUpDialog: func.isRequired,
  restrictedView: bool,
  forceColoredLogo: bool,
  locale: shapes.locale,
  onSetLocale: func,
};

export default appBar;
