import React from "react";
import { Typography, withStyles } from "@material-ui/core";
import Section from "../../lib/hoc/Section";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
  root: {
    paddingTop: theme.metrics.appBarHeight + theme.spacing(6),
  },
});

const TermsOfService = withStyles(styles)(({ classes }) => (
  <Section className={classes.root}>
    <Typography variant="h3" component="h1" gutterBottom>
      <Translate id="TermsOfService.title" />
    </Typography>
    <Typography paragraph>
      <Translate
        id="TermsOfService.content"
        options={{ renderInnerHtml: true }}
      />
    </Typography>
  </Section>
));

export default TermsOfService;
