import { append, prepend } from "../lib/func";
import { keyBy } from "lodash";

const CURRENCIES = [
  { name: "GBP", symbol: "£", format: prepend("£") },
  { name: "EUR", symbol: "€", format: append("€") },
  { name: "USD", symbol: "$", format: prepend("$") },
];

const _CURRENCIES_BY_NAME = keyBy(CURRENCIES, "name");

export default CURRENCIES;
export const format = (cur, value) => _CURRENCIES_BY_NAME[cur].format(value);
