import React, { Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { Typography } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import Grid from "@material-ui/core/Grid";
import features from "../../../refer/features";
import { useWidthDown } from "../../../util/breakpoints";

const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  featImage: {
    width: "100%",
    borderRadius: 5,
    boxShadow: theme.shadows[10],
  },
});

const Features = withStyles(styles)(({ classes }) => {
  const isMobile = useWidthDown("sm");
  return (
    <Section className={classes.root}>
      <Typography
        className={classes.title}
        component="h3"
        color="inherit"
        variant="h4"
      >
        <Translate id="Features.title" options={{ renderInnerHtml: true }} />
      </Typography>
      <Grid container justify="center" spacing={8}>
        {features.map((feature, idx) => (
          <Fragment key={feature.name}>
            <Grid
              item
              xs={12}
              sm={10}
              md={6}
              xl={5}
              style={{ order: idx * 2 + (idx % 2 === 0 || isMobile ? 0 : 1) }}
            >
              <article>
                <Typography variant="subtitle1" color="primary" paragraph>
                  <Translate id={feature.name} />
                </Typography>
                <Typography
                  variant="h5"
                  component="h4"
                  color="inherit"
                  gutterBottom
                >
                  <Translate id={feature.title} />
                </Typography>
                <Typography color="textSecondary">
                  <Translate id={feature.description} />
                </Typography>
              </article>
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={6}
              xl={5}
              style={{ order: idx * 2 + (idx % 2 === 0 || isMobile ? 1 : 0) }}
            >
              <img
                src={feature.image}
                className={classes.featImage}
                alt="Example"
              />
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </Section>
  );
});

Features.propTypes = {};

export default Features;
