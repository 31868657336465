import Benefits from "../localization/strings/Benefits";
import IdeaIcon from "@material-ui/icons/VideogameAsset";
import {
  AcUnit,
  AllInclusive,
  DeviceHub,
  EmojiObjects,
} from "@material-ui/icons";

const icons = {
  freelancers: EmojiObjects,
  features: DeviceHub,
  flexibility: AllInclusive,
  freeze: AcUnit,
};

export default Object.keys(Benefits.benefits).map((name) => ({
  title: `Benefits.benefits.${name}.title`,
  description: `Benefits.benefits.${name}.description`,
  Icon: icons[name] || IdeaIcon,
}));
