import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import channelIcons, { colors } from "../../lib/svg/channelIcons";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
  root: {
    position: "relative",
    zIndex: 5,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  socialIcons: {
    textAlign: "center",
    "& svg": {
      fontSize: "5rem",
      margin: theme.spacing(2),
      "&:hover": {
        animation: `${theme.animations.beat} 0.6s`,
      },
    },
  },
  grouper: {
    display: "inline-block",
  },
});

const SocialHighlights = withStyles(styles)(({ classes }) => (
  <Section className={classes.root}>
    <Grid container alignItems="center" justify="space-around" spacing={8}>
      <Grid item sm={8} md={5}>
        <Typography variant="h3" paragraph>
          <Translate id="SocialHighlights.title" />
        </Typography>
        <Typography color="textSecondary">
          <Translate id="SocialHighlights.subtitle" />
        </Typography>
      </Grid>
      <Grid item sm={8} md={6} className={classes.socialIcons}>
        <div className={classes.grouper}>
          <channelIcons.FacebookIcon htmlColor={colors.facebook} />
          <channelIcons.InstagramIcon htmlColor={colors.instagram} />
        </div>
        <div className={classes.grouper}>
          <channelIcons.LinkedinIcon htmlColor={colors.linkedin} />
          <channelIcons.TwitterIcon htmlColor={colors.twitter} />
        </div>
        <div className={classes.grouper}>
          <channelIcons.PinterestIcon htmlColor={colors.pinterest} />
          <channelIcons.GMBIcon htmlColor={colors.gmb} />
        </div>
        <div className={classes.grouper}>
          <channelIcons.TiktokIcon htmlColor={colors.tiktok} />
        </div>
      </Grid>
    </Grid>
  </Section>
));

SocialHighlights.propTypes = {};

export default SocialHighlights;
