import React from "react";
import { Translate } from "react-localize-redux";
import { deburr, head, isEmpty } from "lodash";

const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i;

const notEmpty = (field) => (value) =>
  value.length <= 0 && <Translate id={`validation.${field}.cannotBeEmpty`} />;

const match = (field, pattern) => (value) =>
  !value.match(pattern) && <Translate id={`validation.${field}.isInvalid`} />;

const atMaxLength = (field, max) => (value) =>
  value.length > max && (
    <Translate id={`validation.${field}.isTooLong`} data={{ max }} />
  );

const atLeastLength = (field, min) => (value) =>
  value.length < min && (
    <Translate id={`validation.${field}.isTooShort`} data={{ min }} />
  );

const hasAlpha = (field) => (value) =>
  !deburr(value).match(/[a-z]+/i) && (
    <Translate id={`validation.${field}.hasNoAlpha`} />
  );

const hasDigit = (field) => (value) =>
  !deburr(value).match(/[0-9]+/i) && (
    <Translate id={`validation.${field}.hasNoDigit`} />
  );

const hasVariedCase = (field) => (value) =>
  value.toLowerCase() === value && (
    <Translate id={`validation.${field}.hasMonotonousCase`} />
  );

const isWhiteAlpha = (field) => (value) =>
  !deburr(value).match(/^['-a-z ]+$/i) && (
    <Translate id={`validation.${field}.isNotOnlyWhiteOrAlpha`} />
  );

/**
 *
 * @param tests {function}
 * @returns {*}
 */
const compose =
  (...tests) =>
  (value) =>
    tests
      .map((test) => test(value)) // Do all tests.
      .filter((res) => Boolean(res)); // filter no errors.

export const checkEmail = compose(
  notEmpty("email"),
  match("email", EMAIL_REGEX)
);

export const checkSignupReason = compose(notEmpty("signupReason"));

export const checkSignupReasonExtended = compose(notEmpty("signupReason"));

export const checkPassword = compose(notEmpty("password"));

export const checkSignUpFirstName = compose(
  notEmpty("firstName"),
  isWhiteAlpha("firstName"),
  atMaxLength("firstName", 100)
);

export const checkSignUpLastName = compose(
  notEmpty("lastName"),
  isWhiteAlpha("lastName"),
  atMaxLength("lastName", 100)
);

export const checkSignUpPassword = compose(
  notEmpty("password"),
  hasDigit("password"),
  hasAlpha("password"),
  hasVariedCase("password"),
  atLeastLength("password", 8),
  atMaxLength("password", 32)
);

export const checkSignUpConfirmPassword = compose(notEmpty("confirmPassword"));

export const checkSignUpReferralCode = compose(atMaxLength("referralCode", 10));

export const checkContactSupportTitle = compose(
  notEmpty("title"),
  atMaxLength(256)
);

export const checkContactSupportDescription = compose(notEmpty("description"));

export const checkContactSupportCategory = compose(
  notEmpty("category"),
  atMaxLength(100)
);

export const checkContactSupportEmail = compose(
  notEmpty("email"),
  match(EMAIL_REGEX)
);

export const checkContactSupportFirstName = compose(
  notEmpty("firstName"),
  atMaxLength(100)
);

export const checkContactSupportLastName = compose(
  notEmpty("latName"),
  atMaxLength(100)
);

export const validateField = (validator, formField) => {
  const errors = validator(formField.value);
  formField.setError(head(errors));
  return isEmpty(errors);
};
