export default {
  title: ["Create a bobcaat account", "Crée ton compte bobcaat"],
  subtitle: [
    "Start your 30-day free trial! Once you've filled out this form, you will receive a confirmation email with a link to confirm your account and access the app. Please check your spam folder just in case.",
    "Démarre ton essai gratuit de 30 jours maintenant ! Après avoir rempli le formulaire ci-dessous, tu recevras un email de confirmation te permettant de te connecter à l'application. Pense à vérifier tes spams.",
  ],
  referralCodeHelper: [
    "In case someone referred the app to you, please enter the code they gave so that we know who to thank. ;)",
    "Si quelqu'un t'a parlé de bobcaat, merci d'indiquer son code de parrainage, que l'on sache qui remercier. ;)",
  ],
  referredBy: ["Referred by: ${referredBy}", "Parrainé par : ${referredBy}"],
  iAgreeToThe: ["I agree to the", "J'accepte les"],
  signUpNow: ["Create account", "Je crée mon compte"],
  passwordsDoNotMatch: [
    "Confirmation password does not match password",
    "Le mot de passe de confirmation est différent.",
  ],
  signupReasonLabel: [
    "How did you hear about bobcaat?",
    "Comment as-tu entendu parler de bobcaat ?",
  ],
  signupReasonOtherLabel: ["Please explain...", "Merci de préciser..."],
  passwordHelperText: [
    "Your password must have at least 8 characters, with a mix of numbers, upper and lowercase letters.",
    "Ton mot de passe doit comporter au moins 8 caractères, avec un mélange de chiffres et de lettres minuscules et majuscules.",
  ]
};
