const contactChannels = [
  {
    name: "facebook",
    target: "_blank",
    href: "https://www.facebook.com/bobcaatsocial",
    className: "fab fa-facebook",
  },
  {
    name: "instagram",
    target: "_blank",
    href: "https://www.instagram.com/bobcaatsocial",
    className: "fab fa-instagram",
  },
  {
    name: "linkedin",
    target: "_blank",
    href: "https://www.linkedin.com/company/bobcaat",
    className: "fab fa-linkedin",
  },
  {
    name: "twitter",
    target: "_blank",
    href: "https://twitter.com/Bobcaatsocial",
    className: "fab fa-twitter",
  },
  {
    name: "email",
    target: undefined,
    href: "mailto:support@bobcaat.com?subject=I%20have%20a%20question!&body=Please%20tell%20us%20what%20you%20think",
    className: "fa fa-envelope",
  },
];
export default contactChannels;
