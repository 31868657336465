import React from "react";
import { render } from "react-snapshot";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { initMomentLocales } from "./localization/moment-i18n";

const root = document.getElementById("root");

if (process.env.REACT_APP_STAGE === "prod") {
  document.head.insertAdjacentHTML('beforeend', '<script async src="https://www.googletagmanager.com/gtag/js?id=G-94N9G13WG2"/>');
  document.head.insertAdjacentHTML('beforeend', "<script>window.dataLayer = window.dataLayer || []; function gtag() {dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'G-94N9G13WG2');</script>")

  // Init logs sink.
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
  });
}

initMomentLocales();

render(<App />, root);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
