import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { fade, Typography } from "@material-ui/core";
import facebookPartnersImg from "../../../assets/images/fb-marketing-partner.png";
import linkedinPartnersImg from "../../../assets/images/li-marketing-partner.jpeg";
import { Translate } from "react-localize-redux";

const styles = (theme) => ({
  root: {
    backgroundColor: fade(theme.palette.secondary.main, 0.4),
    textAlign: "center",
  },
  images: {
    "& img": {
      margin: theme.spacing(1),
      height: 60,
      borderRadius: 5,
    },
  },
});

const Guarantees = withStyles(styles)(({ classes }) => (
  <Section className={classes.root}>
    <Typography gutterBottom component="h3" color="inherit" variant="h5">
      <Translate id="Guarantees.title" />
    </Typography>
    <Typography color="textSecondary">
      <Translate id="Guarantees.subtitle" />
    </Typography>
    <div className={classes.images}>
      <img
        src={facebookPartnersImg}
        alt="Facebook Marketing Partners"
        title="Facebook Marketing Partners"
      />
      <img
        src={linkedinPartnersImg}
        alt="Linkedin Marketing Partners"
        title="Linkedin Marketing Partners"
      />
    </div>
  </Section>
));

Guarantees.propTypes = {};

export default Guarantees;
