export default {
  title: [
    "Scheduling on bobcaat is as easy as pie.",
    "Programmer sur bobcaat, c'est simple comme bonjour.",
  ],
  subtitle: [
    "See for yourself - schedule a post in under 45 seconds.",
    "Vois par toi-même - programme un post en moins de 45 secondes.",
  ],
};
