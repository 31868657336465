import ajax from "../lib/ajax";

export const getInvite = (inviteCode) => ajax.get(`/misc/invite/${inviteCode}`);

export const postSupportRequest = ({
  title,
  description,
  category,
  email,
  firstName,
  lastName,
}) =>
  ajax.post(`/misc/support`, {
    title: title.trim(),
    description,
    category,
    email: email.trim(),
    first_name: firstName.trim(),
    last_name: lastName.trim(),
  });

export const marketingSignUp = ({ email }) => {
  let name = "anonymous";
  try {
    name = email.split("@")[0];
  } catch (e) {
    console.log(e);
  }
  return ajax.post("/marketing/interest", { name, email });
};
