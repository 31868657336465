import React from "react";
import { darken, Link, Typography, withStyles } from "@material-ui/core";
import logo from "../../assets/images/yellowstones.png";
import Grid from "@material-ui/core/Grid";
import channels from "../../refer/contactChannels";
import routes from "../../routes";
import legalDocuments from "../../refer/legalDocuments";
import currencies from "../../refer/currencies";
import locales from "../../localization/locales";
import { wrap } from "lodash";
import { func } from "prop-types";
import { Translate } from "react-localize-redux";
import Section from "../lib/hoc/Section";
import classnames from "classnames";
import stripeSecureImg from "../../assets/images/secure-stripe-payment-logo.png";

const styles = (theme) => ({
  root: {
    backgroundColor: darken(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.contrastText,
  },
  logo: {
    width: "60px",
    marginRight: theme.spacing(1),
  },
  siteMapTitle: {
    color: "inherit",
    marginBottom: theme.spacing(3),
  },
  siteMapLink: {
    cursor: "pointer",
  },
  superFooter: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  socialLink: {
    color: "inherit",
    margin: theme.spacing(0.5),
    fontSize: "2rem",
  },
  images: {
    textAlign: "center",
    paddingTop: theme.spacing(6),
    "& img": {
      maxWidth: 400,
      width: "100%",
    },
  },
});

const siteMapItems = (onSetLocale, onSetCurrency) => [
  {
    name: "Footer.sectionNavigation",
    links: routes.filter(({ hidden }) => !hidden),
  },
  { name: "Footer.sectionLegal", links: legalDocuments },
  {
    name: "global.language",
    links: locales.map((loc) => ({
      label: loc.name,
      click: wrap(loc.slug, onSetLocale),
    })),
  },
  {
    name: "global.currency",
    links: currencies.map((cur) => ({
      label: cur.name,
      click: wrap(cur.name, onSetCurrency),
    })),
  },
];

const SiteMap = ({ onSetCurrency, onSetLocale, classes }) => (
  <Section>
    <Grid container spacing={8} justify="center" alignItems="stretch">
      {siteMapItems(onSetLocale, onSetCurrency).map(({ name, links }) => (
        <Grid item key={name} xs={5} md={2}>
          <Typography variant="subtitle1" className={classes.siteMapTitle}>
            <Translate id={name} />
          </Typography>
          {links.map(({ label, href, click, target }) => (
            <Link
              key={label}
              gutterBottom
              href={href}
              className={classes.siteMapLink}
              onClick={click}
              display="block"
              target={target}
              color="inherit"
            >
              <Translate id={label} />
            </Link>
          ))}
        </Grid>
      ))}
    </Grid>
    <div className={classes.images}>
      <img
        src={stripeSecureImg}
        alt="Stripe secure payments"
        title="Stripe secure payments"
      />
    </div>
  </Section>
);

const SuperFooter = ({ classes }) => (
  <Section className={classes.superFooter}>
    <Grid container alignItems="center" spacing={4} justify="space-around">
      <Grid item xs={12} md="auto">
        <a href="http://yellowstones.io">
          <img src={logo} className={classes.logo} alt="Logo" />
        </a>
      </Grid>
      <Grid item xs={12} md="auto">
        <Typography variant="body2" color="inherit">
          &copy; Yellowstones {new Date().getFullYear()}.<br />
          <Translate id="Footer.contactDetails" />
        </Typography>
      </Grid>
      <Grid item xs={12} md="auto">
        <Typography variant="body2">
          {channels.map(({ className, name, ...props }) => (
            <Link
              {...props}
              color="inherit"
              key={name}
              rel="noopener noreferrer"
            >
              <i className={classnames(className, classes.socialLink)} />
            </Link>
          ))}
        </Typography>
      </Grid>
    </Grid>
  </Section>
);

const footer = withStyles(styles)(({ onSetCurrency, onSetLocale, classes }) => (
  <footer className={classes.root}>
    <SiteMap
      onSetLocale={onSetLocale}
      onSetCurrency={onSetCurrency}
      classes={classes}
    />
    <SuperFooter classes={classes} />
  </footer>
));

footer.propTypes = {
  onSetCurrency: func.isRequired,
  onSetLocale: func.isRequired,
};

export default footer;
