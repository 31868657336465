import { useEffect } from "react";

const useMountEffect = (func) => useEffect(func, []);
/**
 * Same as `useEffect(func, [])`.
 *
 * Easier to read.
 * @param func
 */
export default useMountEffect;
