import React, { useState } from "react";
import { noop, wrap } from "lodash";

const SignInContext = React.createContext({
  onOpenSignIn: noop,
  onCloseSignIn: noop,
  open: false,
});

/**
 * Used only once to wrap the whole app with a signIn state context.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const SignInContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);

  const context = {
    onOpenSignIn: wrap(true, setOpen),
    onCloseSignIn: wrap(false, setOpen),
    open: open,
  };

  return (
    <SignInContext.Provider value={context}>{children}</SignInContext.Provider>
  );
};

export default SignInContext;
