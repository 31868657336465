import { useState } from "react";
import useMountEffect from "./useMountEffect";
import { getURLParam } from "../lib/url";

const DEFAULT_REQUEST_CONTEXT = {
  // A user with an appToken is visiting this page.
  isAuthenticatedUser: false,
};

const buildRequestContext = () => {
  // If an app token is found, user has come from the app to use the contact form.
  // In that case, hide what is outside of that scope.
  // Required for Apple to approve our iOS app.
  const appToken = getURLParam("t");
  if (appToken) {
    // save the token and remove it from path.
    localStorage.appToken = appToken;
    window.history.pushState("", "", document.location.pathname);
  }
  return {
    isAuthenticatedUser: Boolean(appToken),
  };
};

const useRequestContext = () => {
  const [requestContext, setRequestContext] = useState(null);

  useMountEffect(() => {
    // Ensure it is built once only.
    if (typeof requestContext !== "object") {
      setRequestContext(buildRequestContext());
    }
  });

  return requestContext || DEFAULT_REQUEST_CONTEXT;
};
/**
 * Returns some information about the current app request context.
 * @return {{isAuthenticatedUser: boolean}}
 */
export default useRequestContext;
