export default {
  email: {
    cannotBeEmpty: [
      "Email cannot be empty",
      "Tu dois renseigner une adresse email",
    ],
    isInvalid: [
      "Email doesn't look valid",
      "L'adresse email ne semble pas valide",
    ],
  },
  signupReason: {
    cannotBeEmpty: [
      "Please explain how you heard about us. Thanks!",
      "Merci d'expliquer comment tu as entendu parler de nous.",
    ],
  },
  firstName: {
    cannotBeEmpty: [
      "First name cannot be empty",
      "Tu dois renseigner un prénom",
    ],
    isTooLong: [
      "First name should be maximum ${max} characters long",
      "Le prénom ne doit pas dépasser ${max} caractères",
    ],
    isNotOnlyWhiteOrAlpha: [
      "First name includes invalid characters",
      "Le prénom inclut des caractères non autorisés",
    ],
  },
  lastName: {
    cannotBeEmpty: [
      "Last name cannot be empty",
      "Tu dois renseigner un nom de famille",
    ],
    isTooLong: [
      "Last name should be maximum ${max} characters long",
      "Le nom de famille ne doit pas dépasser ${max} caractères",
    ],
    isNotOnlyWhiteOrAlpha: [
      "Last name includes invalid characters",
      "Le nom de famille inclut des caractères non autorisés",
    ],
  },
  password: {
    cannotBeEmpty: [
      "Password cannot be empty",
      "Tu dois renseigner un mot de passe",
    ],
    isTooShort: [
      "Password must be at least ${min} characters long",
      "Le mot de passe doit comporter au moins ${min} caractères",
    ],
    hasMonotonousCase: [
      "Password must include lower and upper case letters",
      "Le mot de passe doit inclure des minuscules et des majuscules",
    ],
    hasNoDigit: [
      "Password must include digits",
      "Le mot de passe doit inclure des chiffres",
    ],
    hasNoAlpha: [
      "Password must include letters",
      "Le mot de passe doit inclure des lettres",
    ],
  },
  confirmPassword: {
    cannotBeEmpty: [
      "Password confirmation cannot be empty",
      "Merci de confirmer le mot de passe",
    ],
  },
  referallCode: {
    isTooLong: [
      "Referral code should be maximum ${max} characters long",
      "Le code de parrainage ne doit pas dépasser ${max} caractères",
    ],
  },
};
