import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { darken, fade, Typography } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { func } from "prop-types";
import { Link } from "react-router-dom";

const styles = (theme) => ({
  root: {
    backgroundColor: darken(theme.palette.primary.main, 0.05),
    color: theme.palette.primary.contrastText,
    textAlign: "center",
  },
  buttons: {
    "& button": {
      margin: theme.spacing(0.5),
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      minWidth: 250,
    },
  },
  ctaButton: {
    color: theme.palette.primary.dark,
  },
  divider: {
    marginTop: theme.spacing(8),
    marginBottom: -theme.spacing(8),
    backgroundColor: fade("#fff", 0.3),
  },
  domLink: {
    textDecoration: "none",
    color: "inherit",
    borderColor: "inherit",
  },
});

const CTA = withStyles(styles)(({ classes, onSignUpNow }) => (
  <Section className={classes.root}>
    <Typography
      gutterBottom
      component="h2"
      color="inherit"
      className={classes.subtitle}
      variant="h3"
    >
      <Translate id="CTA.punchLine" />
    </Typography>
    <div className={classes.buttons}>
      <Button
        size="large"
        onClick={onSignUpNow}
        variant="contained"
        className={classes.ctaButton}
      >
        <Translate id="CTA.callToSignUp" />
      </Button>
      <Link to="/contact" className={classes.domLink}>
        <Button size="large" color="inherit" variant="outlined">
          <Translate id="CTA.callToContactUs" />
        </Button>
      </Link>
    </div>
    <Divider className={classes.divider} />
  </Section>
));

CTA.propTypes = {
  onSignUpNow: func,
};

export default CTA;
