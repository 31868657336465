import React, { useState } from "react";
import { noop } from "lodash";
import currencies from "../refer/currencies";
import { applyIfDefined, withSwitchGuard } from "../lib/func";
import useMountEffect from "../hooks/useMountEffect";

const CurrencyContext = React.createContext({
  onSetCurrency: noop,
  currency: currencies[0],
});

/**
 * Used only once to wrap the whole app with a currency context.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export const CurrencyProvider = ({ children }) => {
  const [currency, setCurrency] = useState("GBP");

  // change only occurs if value is new.
  const handleSetCurrency = withSwitchGuard(currency)((cur) =>
    setCurrency((window.localStorage["currency"] = cur))
  );

  // Reload any saved currency.
  useMountEffect(() =>
    applyIfDefined(handleSetCurrency)(window.localStorage["currency"])
  );

  return (
    <CurrencyContext.Provider
      value={{ onSetCurrency: handleSetCurrency, currency }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContext;
