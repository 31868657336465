import React from "react";
import Footer from "../Footer";
import TermsOfService from "./TermsOfService";
import Helmet from "../lib/Helmet";
import Page from "../lib/hoc/Page";
import useTranslate from "../../hooks/useTranslate";

const TermsOfServicePage = () => {
  const translate = useTranslate();
  return (
    <Page>
      <Helmet
        title={translate("TermsOfServicePage.title")}
        description={translate("TermsOfServicePage.description")}
        keywords={translate("TermsOfServicePage.keywords")}
        url={process.env.REACT_APP_LANDING_URL + "/terms-of-service"}
      />
      <TermsOfService />
      <Footer />
    </Page>
  );
};

export default TermsOfServicePage;
