import React, { useContext } from "react";
import AppBar from "./AppBar";
import { bool } from "prop-types";
import SignUpContext from "../../contexts/signup";
import SignInContext from "../../contexts/signin";
import useLocale from "../../hooks/useLocale";
import useUpdateLanguage from "../../hooks/useUpdateLanguage";
import useRequestContext from "../../hooks/useRequestContext";

const AppBarContainer = ({ forceColoredLogo }) => {
  const { onOpenSignUp } = useContext(SignUpContext);
  const { onOpenSignIn } = useContext(SignInContext);
  const locale = useLocale();
  const setLocale = useUpdateLanguage();
  const { isAuthenticatedUser } = useRequestContext();

  return (
    <AppBar
      restrictedView={isAuthenticatedUser}
      forceColoredLogo={forceColoredLogo}
      onOpenSignInDialog={onOpenSignIn}
      onOpenSignUpDialog={onOpenSignUp}
      locale={locale}
      onSetLocale={setLocale}
    />
  );
};

AppBarContainer.propTypes = {
  forceColoredLogo: bool,
};

export default AppBarContainer;
