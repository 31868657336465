import React, { useContext } from "react";
import Jumbo from "./Jumbo";
import SignUpContext from "../../../contexts/signup";

const JumboContainer = () => {
  const { onOpenSignUp } = useContext(SignUpContext);

  return <Jumbo onSignUpNow={onOpenSignUp} />;
};

export default JumboContainer;
