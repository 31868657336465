export default {
  title: [
    "bobcaat - Newsletter terms of agreement",
    "bobcaat - Conditions de la newsletter",
  ],
  description: [
    "Read bobcaat's terms of service before subscribing to our newsletter.",
    "Lis nos conditions d'utilisation avant de souscrire à notre newsletter.",
  ],
  keywords: [
    "bobcaat, yellowstones, social media, social media platform, social media tool, terms of service",
    "bobcaat, yellowstones, réseaux sociaux, plateforme réseaux sociaux, outil réseaux sociaux, conditions d'utilisation",
  ],
};
