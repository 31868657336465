import React from "react";
import { Fade, fade, Slide, Typography, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { Translate } from "react-localize-redux";
import jumboBack from "../../../assets/images/jumbo.jpg";
import bobcaatScreen from "../../../assets/images/bobcaat-screen.png";
import Wave from "../../lib/svg/Wave";
import useTheme from "@material-ui/core/styles/useTheme";
import { Link } from "react-router-dom";
import { Link as NativeLink } from "@material-ui/core";
import AppStoreButton from "../../lib/svg/AppStoreButton";

const styles = (theme) => ({
  root: {
    paddingTop: theme.metrics.appBarHeight,
    backgroundImage: `url('${jumboBack}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    position: "relative",
    overflow: "hidden",
    minHeight: 900,
  },
  flex: {
    display: "flex",
    flexDirection: "column",
  },
  overlay: {
    position: "absolute",
    zIndex: 0,
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundColor: fade(theme.palette.secondary.main, 0.9),
  },
  grid: {
    position: "relative",
    zIndex: 2,
    padding: theme.spacing(4),
    maxWidth: (props) => (props.fullWidth ? undefined : 1200),
    margin: "auto",
  },
  wave: {
    position: "absolute",
    zIndex: 1,
    bottom: 0,
    left: 0,
    width: "100vw",
  },
  buttons: {
    "& button": {
      margin: theme.spacing(0.5),
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  phoneScreen: {
    width: 300,
    marginLeft: 150,
  },
  downloadAppleAppBtn: {
    marginTop: theme.spacing(2),
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  domLink: {
    textDecoration: "none",
    color: "inherit",
    borderColor: "inherit",
  },
});

const MainGrid = ({ classes, onSignUpNow }) => (
  <Grid container className={classes.grid} justify="center" alignItems="center">
    <Fade in timeout={500} style={{ transitionDelay: "200ms" }}>
      <Grid item xs={12} md={6} lg={7}>
        <Typography
          className={classes.title}
          gutterBottom
          component="h1"
          color="inherit"
          variant="h3"
        >
          <Translate id="Jumbo.title" />
        </Typography>
        <Typography
          gutterBottom
          component="h2"
          color="inherit"
          className={classes.subtitle}
          variant="h5"
        >
          <Translate id="Jumbo.subtitle" options={{ renderInnerHtml: true }} />
        </Typography>
        <div className={classes.buttons}>
          <Button
            size="large"
            onClick={onSignUpNow}
            variant="contained"
            color="primary"
          >
            <Translate id="Jumbo.callToSignUp" />
          </Button>
          <Link to="/contact" className={classes.domLink}>
            <Button size="large" variant="outlined">
              <Translate id="Jumbo.callToContactUs" />
            </Button>
          </Link>
          <div>
            <NativeLink
              target="_blank"
              href="https://apps.apple.com/us/app/bobcaat/id1495402754"
              rel="noopener noreferrer"
            >
              <AppStoreButton className={classes.downloadAppleAppBtn} />
            </NativeLink>
          </div>
        </div>
      </Grid>
    </Fade>
    <Grid item xs={12} md={6} lg={5}>
      <Slide in direction="left" timeout={1000}>
        <img
          src={bobcaatScreen}
          alt="Phone screen"
          className={classes.phoneScreen}
        />
      </Slide>
    </Grid>
  </Grid>
);

const Jumbo = ({ onSignUpNow, classes }) => {
  const theme = useTheme();
  return (
    <section role="banner" className={classes.root}>
      <div className={classes.flex}>
        <div className={classes.overlay} />
        <MainGrid classes={classes} onSignUpNow={onSignUpNow} />
        <Wave
          className={classes.wave}
          fill={theme.palette.background.default}
        />
      </div>
    </section>
  );
};

export default withStyles(styles)(Jumbo);
