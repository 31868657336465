import React from "react";
import Helmet from "../lib/Helmet";
import useTranslate from "../../hooks/useTranslate";
import Footer from "../Footer";
import Page from "../lib/hoc/Page";
import FAQ from "./FAQ";

const FaqPage = () => {
  const translate = useTranslate();
  return (
    <Page>
      <Helmet
        title={translate("FAQPage.title")}
        description={translate("FAQPage.description")}
        keywords={translate("FAQPage.keywords")}
        url={process.env.REACT_APP_LANDING_URL + "/faq"}
      />
      <FAQ />
      <Footer />
    </Page>
  );
};

export default FaqPage;
