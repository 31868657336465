import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section from "../../lib/hoc/Section";
import { Typography } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import Grid from "@material-ui/core/Grid";
import benefits from "../../../refer/benefits";

const styles = (theme) => ({
  root: {},
  icon: {
    color: theme.palette.secondary.main,
    fontSize: "2rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("xs")]: {
      fontSize: "5rem",
      margin: "auto",
      display: "block",
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    marginBottom: theme.spacing(8),
  },
  article: {
    maxWidth: 500,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
});

const Benefits = withStyles(styles)(({ classes }) => (
  <Section className={classes.root}>
    <Typography paragraph component="h3" color="primary" variant="subtitle1">
      <Translate id="Benefits.subtitle" />
    </Typography>
    <Typography
      className={classes.title}
      component="h4"
      color="inherit"
      variant="h4"
    >
      <Translate id="Benefits.title" />
    </Typography>
    <Grid container justify="flex-start" spacing={0}>
      {benefits.map((benefit) => (
        <Grid item key={benefit.title} xs={12} sm={6} md={6} lg={6} xl={3}>
          <article className={classes.article}>
            <benefit.Icon className={classes.icon} />
            <Typography
              color="textPrimary"
              component="h5"
              variant="h6"
              gutterBottom
            >
              <Translate id={benefit.title} />
            </Typography>
            <Typography color="textSecondary">
              <Translate id={benefit.description} />
            </Typography>
          </article>
        </Grid>
      ))}
    </Grid>
  </Section>
));

export default Benefits;
