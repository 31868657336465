import React, { useContext } from "react";
import Contact from "./Contact";
import { every } from "lodash";
import {
  checkContactSupportCategory,
  checkContactSupportDescription,
  checkContactSupportEmail,
  checkContactSupportFirstName,
  checkContactSupportLastName,
  checkContactSupportTitle,
  validateField,
} from "../../../lib/validation";
import useForm from "../../../hooks/useForm";
import useRequestContext from "../../../hooks/useRequestContext";
import SnackContext from "../../../contexts/snacks";
import { Translate } from "react-localize-redux";
import * as misc from "../../../api/misc";
import { getURLParam } from "../../../lib/url";

const ContactContainer = () => {
  const { isAuthenticatedUser } = useRequestContext();
  const { onSetSuccess } = useContext(SnackContext);
  const contactForm = useForm({
    category: getURLParam("topic") ?? "",
    title: "",
    email: "",
    lastName: "",
    firstName: "",
    description: "",
  });

  const handleSubmit = () => {
    const isValid = every(
      [
        validateField(checkContactSupportTitle, contactForm.title),
        isAuthenticatedUser ||
          validateField(checkContactSupportEmail, contactForm.email),
        isAuthenticatedUser ||
          validateField(checkContactSupportFirstName, contactForm.firstName),
        isAuthenticatedUser ||
          validateField(checkContactSupportLastName, contactForm.lastName),
        validateField(checkContactSupportDescription, contactForm.description),
        validateField(checkContactSupportCategory, contactForm.category),
      ],
      Boolean
    );

    if (isValid) {
      misc.postSupportRequest(contactForm.collect()).then(() => {
        onSetSuccess(<Translate id="Contact.onSendSuccess" />);
        contactForm.clear();
      });
    }
  };

  return (
    <Contact
      contactForm={contactForm}
      isAuthenticatedUser={isAuthenticatedUser}
      onSubmit={handleSubmit}
    />
  );
};

ContactContainer.propTypes = {};

export default ContactContainer;
