export default {
  title: [
    "NEWSLETTER TERMS & CONDITIONS",
    "NEWSLETTER - CONDITIONS D'UTILISATION",
  ],
  content: [
    "Yellowstones is committed to protecting your privacy.<br/><br/>" +
      "The information that you have supplied will be used " +
      "by Yellowstones to keep you up to date with news, security updates, " +
      "events, and special offers about bobcaat, based on your preferences. " +
      "Yellowstones will not share your details with any other company or " +
      "institution without your consent.<br/><br/>" +
      "If you engage in any of our services after reading " +
      "our newsletter, then you will be covered by the Yellowstones " +
      "Customer Privacy Policy.<br/><br/>" +
      "You may opt out of receiving information about our " +
      "services, future events, and developments by selecting the relevant " +
      "option on any of newsletter emails or by sending us an email at " +
      "<a href='mailto:hello@yellowstones.io'>hello@yellowstones.io</a>.<br/><br/>" +
      "After signing up, you will receive a confirmation email at the address you " +
      "supplied.",
    "Yellowstones s'engage à protéger votre vie privée.<br/><br/>" +
      "Les informations que vous avez fournies seront utilisées par Yellowstones pour " +
      "vous tenir au courant des actualités, des mises à jour de sécurité, des " +
      "événements et des offres spéciales en relation avec bobcaat, selon vos " +
      "préférences. Yellowstones ne partagera pas vos informations avec une autre " +
      "société ou institution sans votre consentement.<br/><br/>" +
      "Si vous utilisez l'un de nos services après avoir lu notre newsletter, vous " +
      "serez couvert par la politique de confidentialité de Yellowstones.<br/><br/>" +
      "Vous pouvez refuser de recevoir des notifications à propos de nos services, nos " +
      "événements futurs et nos avancées en sélectionnant l'option dédiée dans nos " +
      "newsletters ou en nous envoyant un email à l'adresse " +
      "<a href='mailto:hello@yellowstones.io'>hello@yellowstones.io</a>.<br/><br/>" +
      "Après vous être inscrit(e), vous recevrez un email de confirmation à l'adresse " +
      "fournie.",
  ],
};
