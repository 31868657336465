import dashboardImage from "../assets/images/features/dashboard.png";
import editorImage from "../assets/images/features/editor.png";
import calendarImage from "../assets/images/features/calendar.png";
import hashtagsImage from "../assets/images/features/hashtags.png";
import analyticsImage from "../assets/images/features/analytics.png";
import engagementImage from "../assets/images/features/engagement.png";
import Features from "../localization/strings/Features";

const images = {
  editor: editorImage,
  calendar: calendarImage,
  hashtags: hashtagsImage,
  dashboard: dashboardImage,
  analytics: analyticsImage,
  engagement: engagementImage,
};

export default Object.keys(Features.features).map((name) => ({
  name: `Features.features.${name}.name`,
  title: `Features.features.${name}.title`,
  description: `Features.features.${name}.description`,
  image: images[name] || dashboardImage,
}));
