import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import * as themes from "./themes";
import "./assets/styles/animations.css";
import "./assets/styles/raleway.css";
import FBCustomerChat from "./components/lib/FBCustomerChat";
import { CurrencyProvider } from "./contexts/currency";
import { SnackProvider } from "./contexts/snacks";
import { LocalizationProvider } from "./contexts/localization";
import { SignUpContextProvider } from "./contexts/signup";
import { SignInContextProvider } from "./contexts/signin";
import AjaxErrorInterceptor from "./components/lib/hoc/AjaxErrorInterceptor";
import routes from "./routes";
import SignUpDialog from "./components/SignUpDialog";
import SignInDialog from "./components/SignInDialog";
import AppBar from "./components/AppBar";

const Main = withRouter(({ location }) => {
  return (
    <SignUpContextProvider>
      <SignInContextProvider>
        <AppBar
          forceColoredLogo={!["/", "/about"].includes(location.pathname)}
        />
        <SignUpDialog />
        <SignInDialog />
        <Switch>
          {routes.map(({ href, exact, Component }) => (
            <Route key={href} path={href} exact={exact}>
              <Component />
            </Route>
          ))}
          <Redirect to="/" />
        </Switch>
      </SignInContextProvider>
    </SignUpContextProvider>
  );
});

const app = () => (
  <MuiThemeProvider theme={themes.light}>
    <CssBaseline />
    <div>
      <LocalizationProvider>
        <SnackProvider>
          <AjaxErrorInterceptor>
            <CurrencyProvider>
              <BrowserRouter>
                <Main />
              </BrowserRouter>
            </CurrencyProvider>
          </AjaxErrorInterceptor>
        </SnackProvider>
      </LocalizationProvider>
    </div>
    <FBCustomerChat />
  </MuiThemeProvider>
);
export default app;
