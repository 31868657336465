import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { bool, func, object } from "prop-types";
import LockIcon from "@material-ui/icons/Lock";
import { Typography } from "@material-ui/core";
import { useWidthDown } from "../../util/breakpoints";
import { Translate } from "react-localize-redux";
import { isEmpty } from "lodash";

const SignInDialog = ({ open, onClose, onSubmit, onResetPassword, form }) => {
  const isMobile = useWidthDown("xs");

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
      fullScreen={isMobile}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title" disableTypography>
        <Typography component="div" align="center">
          <LockIcon color="secondary" fontSize="large" />
        </Typography>
        <Typography component="h3" variant="h6" align="center">
          <Translate id="SignInDialog.title" />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Translate id="SignInDialog.subtitle" />
        </DialogContentText>
        <TextField
          margin="dense"
          name="email"
          label={<Translate id="global.email" />}
          value={form.email.value}
          onChange={(e) => form.email.setValue(e.currentTarget.value)}
          type="email"
          fullWidth
          onKeyDown={handleKeyDown}
          required
          helperText={form.email.error || ""}
          error={Boolean(form.email.error)}
        />
        <TextField
          margin="dense"
          name="password"
          autoComplete="new-password"
          label={<Translate id="global.password" />}
          fullWidth
          onChange={(e) => form.password.setValue(e.currentTarget.value)}
          type="password"
          value={form.password.value}
          helperText={form.password.error || ""}
          required
          error={Boolean(form.password.error)}
          onKeyDown={handleKeyDown}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onResetPassword}
          disabled={isEmpty(form.email.value)}
        >
          <Translate id="global.resetPassword" />
        </Button>
        <Button onClick={onClose} color="primary">
          <Translate id="global.cancel" />
        </Button>
        <Button color="primary" onClick={onSubmit}>
          <Translate id="global.ok" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SignInDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  onResetPassword: func.isRequired,
  form: object,
};

export default SignInDialog;
